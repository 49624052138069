import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow_right_icon.svg";

const ProfileOption = ({ text, redirect, history }) => {

  return (
    <div
      className="profile__options"
      onClick={() => history.push(`/${redirect}`)}
    >
      <p className="options__text">{text}</p>
      <ArrowIcon className="options__icon" />
    </div>
  );
};

export default ProfileOption;
