import styled from "styled-components";
import { colors } from "../variables";

export const BoxAuth = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo__container {
    text-align: center;
    margin-bottom: 20px;
    .logo {
      width: 283px;
      height: 81px;
    }
  }

  .register-options__container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      margin: 140px 0 50px;
    }

    div {
      display: inline-block;
      width: 370px;
      height: 40px;
      text-align: center;
      margin: 0 auto 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-radius: 6px;
    }

    a,
    div p {
      display: block;
      text-decoration: none;
    }

    .icon {
      height: 33px;
      width: 32px;
      margin-left: 20px;
    }

    .google {
      border: 1px solid ${colors.grayBorder};
      display: flex;
      flex-direction: row;
      a {
        align-self: center;
        color: ${colors.mediumGrayMDM};
        font-size: 20px;
        font-weight: 700;
        line-height: 27.24px;
        width: 100%;
      }
    }

    .facebook {
      border: 1px solid ${colors.grayBorder};
      display: flex;
      flex-direction: row;
      a {
        align-self: center;
        color: ${colors.mediumGrayMDM};
        font-size: 20px;
        font-weight: 700;
        line-height: 27.24px;
        width: 100%;
      }
    }

    .correo {
      border: 1px solid ${colors.grayBorder};
      display: flex;
      flex-direction: row;
      color: white;
      cursor: pointer;

      p {
        align-self: center;
        color: ${colors.mediumGrayMDM};
        font-size: 20px;
        font-weight: 700;
        line-height: 27.24px;
        width: 100%;
      }
    }

    .register {
      width: 420px;
      text-align: end;
      color: ${colors.blueInteraction};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 80px;

    .logo__container {
      margin-bottom: 0;
      .logo {
        width: 146px;
        height: 41px;
      }
    }

    .logo {
      margin-bottom: 0;
      width: 230px;
    }

    .register-options__container {
      h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 59px;
        margin-top: 50px;
      }

      .google,
      .facebook,
      .correo {
        display: flex;
        flex-direction: row;
        width: 280px;
        a {
          align-self: center;
          font-size: 14px;
          line-height: 19.07px;
          margin-left: 0;
          width: 100%;
        }

        p {
          align-self: center;
          font-size: 14px;
          line-height: 19.07px;
          margin-left: 0;
          width: 100%;
        }
      }

      .correo {
        margin-bottom: 112px;
      }

      .register {
        display: flex;
        flex-direction: column;
        text-align: center;
        text-decoration: none;
        width: 280px;

        &__question {
          color: #181818;
          font-size: 12px;
          font-weight: 400;
          line-height: 16.34px;
        }

        &__invite {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    padding-top: 80px;

    .logo__container {
      margin-bottom: 0;
      .logo {
        width: 270px;
      }
    }

    .register-options__container {
      h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 59px;
        margin-top: 50px;
      }

      .google,
      .facebook,
      .correo {
        display: flex;
        flex-direction: row;
        width: 327px;
        a {
          align-self: center;
          font-size: 14px;
          line-height: 19.07px;
          width: 100%;
        }

        p {
          align-self: center;
          font-size: 14px;
          line-height: 19.07px;
          width: 100%;
        }
      }

      .correo {
        margin-bottom: 112px;
      }
      .register {
        display: flex;
        flex-direction: column;
        text-align: center;
        text-decoration: none;
        width: 280px;

        &__question {
          color: #181818;
          font-size: 12px;
          font-weight: 400;
          line-height: 16.34px;
        }

        &__invite {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 80px;

    .logo__container {
      margin-bottom: 0;
      .logo {
        width: 270px;
      }
    }

    .register-options__container {
      h2 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 59px;
        margin-top: 50px;
      }

      .google,
      .facebook,
      .correo {
        display: flex;
        flex-direction: row;
        width: 638px;
        height: 78px;
        a {
          align-self: center;
          font-size: 20px;
          line-height: 27px;
          margin-left: 0;
          width: 100%;
        }

        p {
          align-self: center;
          font-size: 20px;
          line-height: 27px;
          margin-left: 0;
          width: 100%;
        }
      }

      .correo {
        margin-bottom: 112px;
      }
      .register {
        display: flex;
        flex-direction: column;
        text-align: center;
        text-decoration: none;
        width: 280px;

        &__question {
          color: #181818;
          font-size: 18px;
          line-height: 25px;
          font-weight: 400;
        }

        &__invite {
          text-decoration: underline;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    height: inherit;

    .logo__container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 50%;
    }

    .register-options__container {
      width: 50%;

      h2 {
        margin-top: 0;
      }

      div {
        width: 468px;
        height: 55px;
      }

      .register {
        display: flex;
        flex-direction: column;
        margin-top: 53px;
        text-align: center;
        text-decoration: none;
        width: 280px;

        &__question {
          color: #181818;
          font-size: 18px;
          line-height: 24.51px;
        }

        &__invite {
          font-size: 14px;
          line-height: 19.07px;
          text-decoration: underline;
        }
      }
    }
  }
`;
