const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']

const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes']

export const convertDate = date => {
  const englishDay = date.slice(0, 3)
  const englishMonth = date.slice(4, 7)
  let monthSpanish
  let daySpanish


  switch (englishMonth) {
    case 'Jan':
      monthSpanish = months[0]
      break;
    case 'Feb':
      monthSpanish = months[1]
      break;
    case 'Mar':
      monthSpanish = months[2]
      break;
    case 'Apr':
      monthSpanish = months[3]
      break;
    case 'May':
      monthSpanish = months[4]
      break;
    case 'Jun':
      monthSpanish = months[5]
      break;
    case 'Jul':
      monthSpanish = months[6]
      break;
    case 'Aug':
      monthSpanish = months[7]
      break;
    case 'Sep':
      monthSpanish = months[8]
      break;
    case 'Oct':
      monthSpanish = months[9]
      break;
    case 'Nov':
      monthSpanish = months[10]
      break;
    case 'Dec':
      monthSpanish = months[11]
      break;
    default:
      monthSpanish = 'El mes no es válido'
      break;
  }

  switch(englishDay){
    case 'Mon':
        daySpanish = days[0]
      break;
    case 'Tue':
      daySpanish = days[1]
      break;
    case 'Wed':
      daySpanish = days[2]
      break;
    case 'Thu':
      daySpanish = days[3]
      break;
    case 'Fri':
      daySpanish = days[4]
      break;
    case 'Sat':
      daySpanish = days[5]
      break;
    case 'Sun':
      daySpanish = days[6]
      break;
    default:
      daySpanish = 'El día no es válido'
      break;
  }

  const completeDay = `${daySpanish} ${date.slice(8, 10)} de ${monthSpanish} de ${date.slice(11, 15)}`

  return {
    completeDay
  }
}