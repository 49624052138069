import { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Import stipe elements
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'

// Import hooks
import useInput from '../../hooks/useInput'
import { Context } from '../../context'

// Import services
import { subscription, currentUser } from '../../services/auth'

// Import styles
import { PaymentPage } from '../../styles/auth/PaymentPage.styled'
// import { ButtonCont, override } from "../../styles/PaymentFailure/PaymentFailure.styled";

import BeatLoader from "react-spinners/BeatLoader";

// Crete stripe promise
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)


const CardForm = ({ monthlyPlans, setLoading, loading }) => {
  const history = useHistory()
  const { userCtx, setUserCtx } = useContext(Context)
  const search = useLocation().search
  const userId = new URLSearchParams(search).get('user')
  const idSend = userId ? userId : userCtx._id

  const dictionaryError =
  {
    "Your card was declined.": "Lo sentimos, la tarjeta que intentas registrar fue declinada. Revisa que todo esté en orden con tu institución financiera o intenta registrando otra tarjeta.",
    "Your card's security code is incorrect.": "Lo sentimos, el código de seguridad de la tarjeta es incorrecto. Revisa que lo hayas registrado correctamente o intenta registrando otra tarjeta.",
    "Your card has insufficient funds.": "Lo sentimos, los fondos de la tarjeta son insuficientes. Revisa que todo esté en orden con tu institución financiera o  intenta registrando otra tarjeta.",
    "Your card has expired.": "Lo sentimos, la tarjeta que intentas registrar está expirada. Por favor intenta registrando otra tarjeta.",
    "An error occurred while processing your card. Try again in a little bit.": "Lo sentimos, ocurrió un error inesperado. Por favor intenta hacer tu registro mas tarde.",
  }

  const nameInput = useInput('')
  const [numberStatus, setNumberStatus] = useState(false)
  const [CVVStatus, setCVVStatus] = useState(false)
  const [periodStatus, setPeriodStatus] = useState(false)
  const [error, setError] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true);
    const cardNumber = elements.getElement(CardNumberElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: nameInput.value
      }
    })

    if (error) {
      console.log('[error]', error)
      setLoading(false);
    } else {
      try {
        const token = await subscription(idSend, paymentMethod.id,
          { monthlyPlans }
        )
        localStorage.setItem('token', token.data.token)
        const user = await currentUser()
        setUserCtx(user.data.userForToken)
        history.push('/success')
      } catch (error) {
        setLoading(false)
        if (error.response.data.raw.message)
          setError(dictionaryError[error.response.data.raw.message] ? dictionaryError[error.response.data.raw.message] : "Ha ocurrido un error")
        else
          console.log(error)
      }
    }
  }

  return (
    <PaymentPage>
      <h2 className="paymentmethod__title">Medio de pago</h2>
      <p className="paymentmethod__selectedplan">Seleccionaste el <span>{monthlyPlans ? 'Plan Mensual' : 'Plan Anual'}</span> por {monthlyPlans ? '$499.00' : '$4,799.00'}.
        <br />
        Al término de tu periodo de 7 días de prueba, se hará el cargo a tu tarjeta.
      </p>
      <form className="paymentmethod__form" onSubmit={handleSubmit}>
        <div className="paymentmethod__input">
          <label htmlFor="name">Nombre del titular</label>
          <input type="text" name="name" id="name" placeholder="Ingresa el nombre del titular" {...nameInput} />
        </div>
        <label>Número de tarjeta</label>
        <CardNumberElement className="card" onChange={e => setNumberStatus(e.complete)} />
        <div className="paymentmethod__card-security">
          <div>
            <label>CVV</label>
            <CardCvcElement className="card card-half" onChange={e => setCVVStatus(e.complete)} />
          </div>
          <div>
            <label>Vigencia</label>
            <CardExpiryElement className="card card-half" onChange={e => setPeriodStatus(e.complete)} />
          </div>
        </div>
        {error && <p className="error">{error}</p>}
        <button className="paymentmethod__submit" type="submit" disabled={nameInput.value === '' || !numberStatus || !CVVStatus || !periodStatus}>
          {loading ?
            <BeatLoader color={"#ffffff"} loading={true} size={15} margin={2} />
            : "Continuar"
          }
        </button>
      </form>
    </PaymentPage>
  )
}


const Payment = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CardForm {...props} />
    </Elements>
  )
}

export default Payment
