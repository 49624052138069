import styled from "styled-components";
import { colors } from "../variables";

export const ManageAccountContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  ${"" /* padding: 75px 10% 50px; */}
  padding: 90px 10% 0;
  width: 100vw;

  ${"" /* .manage-account {
    &__header {
      align-items: center;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(252, 252, 255, 1) 10%,
        rgba(255, 255, 255, 1) 100%
      );
      display: flex;
      height: 55px;
      justify-content: space-between;
      margin-bottom: 52px;
      top: 55px;
      width: 100%;
    }

    &__back {
      color: ${colors.blueInteraction};
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: bold;
    }

    &__arrow {
      fill: ${colors.blueInteraction};
      height: 20px;
      margin-right: 7px;
      width: 12px;
    }

    &__logout {
      color: ${colors.blueInteraction};
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
    }
  } */
  }

  .manage-account__cards {
    align-items: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .account-card__info {
      align-items: center;
      border-radius: 4px;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      margin-bottom: 21px;
      padding: 30px 0 0;
      position: relative;
      width: 100%;

      .card {
        &__top-border {
          border-radius: 4px 4px 0 0;
          border-top: 13px solid ${colors.blueInteraction};
          height: 13px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &__title {
          font-family: "Crimson";
          font-size: 24px;
          margin-bottom: 5px;
          width: 225px;
        }

        &__text {
          font-family: "Open Sans";
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 5px;
          width: 225px;
        }

        &__price {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 25px;
          width: 225px;
        }

        &__change-plan {
          background-color: ${colors.blueInteraction};
          border-radius: 2px;
          border: none;
          color: white;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 600;
          height: 30px;
          margin-bottom: 15px;
          width: 185px;
        }

        &__cancel-plan {
          background: none;
          border: none;
          color: ${colors.blueInteraction};
          font-size: 13px;
          margin-bottom: 20px;
          text-decoration: underline;
        }
      }
    }

    .payment-cards {
      align-items: center;
      border-radius: 4px;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      padding: 30px 0 0;
      position: relative;
      width: 100%;

      .card__top-border {
        border-radius: 4px 4px 0 0;
        border-top: 13px solid ${colors.blueInteraction};
        height: 13px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .payment__header {
        display: flex;
        flex-direction: column;
        width: inherit;

        .payment__stripe-icon {
          align-self: flex-end;
          height: 19px;
          width: 68px;
        }

        .card__title {
          font-family: DM Serif Display;
          font-size: 24px;
          margin-bottom: 10px;
          width: inherit;
          text-align: left;
          margin-left: 26px;
          line-height: 33px;
        }
      }

      .card {
        &__container {
          height: 50%;
          overflow: scroll;
          width: inherit;
        }

        &__new-payment-method {
          background-color: ${colors.blueInteraction};
          border-radius: 2px;
          border: none;
          color: white;
          float: right;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          height: 40px;
          margin: 25px 10px 30px;
          width: 155px;
        }
      }
    }
  }

  .receipts-record {
    margin-top: 28px;
    width: 100%;

    &__title {
      font-family: DM Serif Display;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 30px;
    }

    .receipt__container {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      background: #EEEEEE;
      align-items: center;
      .receipt {
        &__date {
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
        }
        
        &__quote {
          display: none;
        }

        &__quantity-brand {
          display: flex;
          flex-direction: row;
          .receipt__quantity {
            font-size: 18px;
            font-weight: normal;
            line-height: 25px;
            margin-right: 16px;
          }
          
          .receipt__brand {
            font-size: 18px;
            font-weight: normal;
            line-height: 25px;
          }
        }
      }
    }
  }

  .account__cancel {
    position: sticky;
    top: 100vh;
    padding-bottom: 20px;
    color: ${colors.darkGrayMDM};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    margin: 35px 0 30px;
    text-align: center;
    width: 100%;
    :hover{
      cursor: pointer;
      color: ${colors.blueFacebook}
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 100px 5% 0;

    .manage-account__cards {
      flex-direction: row;
      .account-card__info {
        margin: 0 20px 0 0;
        padding: 30px 20px 0 42px;
        width: 250px;
        .card {
          &__title {
            font-size: 20px;
          }

          &__text {
            font-size: 13px;
          }

          &__price {
            font-size: 21px;
          }
        }
      }

      .payment-cards {
        .payment__header {
          .payment__stripe-icon {
            padding-right: 25px;
            width: 80px;
          }

          .card__title {
            text-align: start;
          }
        }

        .card {
          &__title {
            font-size: 20px;
            padding-left: 20px;
            width: 100%;
          }

          &__container {
            padding-left: 20px;
          }

          &__new-payment-method {
            align-self: flex-end;
            margin: 11px 12px 33px 0;
          }
        }
      }
    }

    .receipts-record {
      margin-top: 65px;
      padding: 0 90px;

      &__title {
        margin-bottom: 42px;
      }

      .receipt__container {
        width: 100%;
        .receipt {
          &__date {
            font-size: 14px;
          }
        
          &__quote {
            display: flex;
          }

          &__quantity-brand {
            justify-content: space-between;
            width: 60%;

            .receipt__quantity {
              font-size: 14px;
              margin-right: 0;
            }

            .receipt__brand {
              font-size: 14px;
            }
          }
        }
      }
    }

    .account__cancel {
      top: 90vh;
      padding-bottom: 30px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    padding: 120px 10% 0;
    ${"" /* .manage-account {
      &__header {
        margin-bottom: 94px;
        padding: 0 85px;
      }
    } */
  }

    .manage-account__cards {
      flex-direction: row;
      .account-card__info {
        margin: 0 20px 0 0;
        width: 310px;
        .card {
          &__title {
            font-size: 24px;
          }

          &__text {
            font-size: 18px;
          }

          &__price {
            font-size: 24px;
          }
        }
      }

      .payment-cards {
        width: 530px;

        .payment__header {
          .payment__stripe-icon {
            padding-right: 25px;
            width: 95px;
          }

          .card__title {
            text-align: start;
          }
        }

        .card {
          &__title {
            font-size: 24px;
            padding-left: 20px;
            width: 100%;
          }

          &__container {
            height: 55%;
            padding-left: 20px;
          }

          &__new-payment-method {
            align-self: flex-end;
            margin: 0 12px 33px 0;
          }
        }
      }
    }

    .receipts-record {
      margin-top: 65px;
      padding: 0 90px;

      &__title {
        margin-bottom: 42px;
      }

      .receipt__container {}
        width: 100%
        .receipt {
          &__date {
            font-size: 18px;
          }
          
          &__quote {
            display: flex;
          }

          &__quantity-brand {
            justify-content: space-between;
            width: 60%;

            .receipt__quantity {
              font-size: 14px;
              margin-right: 0;
            }

            .receipt__brand {
              font-size: 14px;
            }
          }
        }
      }
    }

    .account__cancel {
      top: 90vh;
      padding-bottom: 30px;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 100px 20% 0;

    .manage-account__cards {
      flex-direction: row;
      .account-card__info {
        margin: 0 20px 0 0;
        width: 40%;
        .card {
          &__title {
            font-size: 24px;
            padding-left: 10%;
            width: 100%;
          }

          &__text {
            font-size: 18px;
            padding-left: 10%;
            width: 100%;
          }

          &__price {
            font-size: 24px;
            padding-left: 10%;
            width: 100%;
          }
        }
      }

      .payment-cards {
        padding-bottom: 30px;
        width: 60%;

        .payment__header {
          flex-direction: row-reverse;
          width: 100%;

          .payment__stripe-icon {
            align-self: baseline;
            height: 29px;
            margin-right: 20px;
            width: 104px;
          }

          .card__title {
            text-align: start;
          }
        }

        .card {
          &__title {
            font-size: 24px;
            padding-left: 50px;
            width: 100%;
          }

          &__container {
            height: 55%;
            padding-left: 50px;
            width: 100%;
          }

          &__new-payment-method {
            align-self: flex-end;
            margin: 15px 20% 0 0;
          }
        }
      }
    }

    .receipts-record {
      margin-top: 65px;

      &__title {
        margin-bottom: 42px;
      }

      .receipt__container {
        justify-content: space-between;
        width: 100%;

        .receipt {
          &__date {
            font-size: 18px;
          }
          
          &__quote {
            display: flex;
          }

          &__quantity-brand {
            margin-left: 95px;
            justify-content: space-between;
            width: 60%;

            .receipt__quantity {
              font-size: 18px;
              margin-right: 0;
            }

            .receipt__brand {
              font-size: 18px;
            }
          }
        }
      }
    }

    .account__cancel {
      top: 90vh;
      padding-bottom: 30px;
    }
  }
`;

export const AddPaymentModal = styled.div `
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 30px;
  border-radius: 20px;
  background: ${colors.blueFacebook};
`;