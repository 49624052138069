import { useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Import services
import { findUser, currentUser } from '../../services/auth'

// Import context
import { Context } from '../../context'

const ProcessLogin = () => {
  const history = useHistory()
  const search = useLocation().search
  const userId = new URLSearchParams(search).get('user')
  const { setUserCtx } = useContext(Context)

  useEffect(() => {
    const getUserInfo = async idOfUser => {
      const token = await findUser(idOfUser)
      localStorage.setItem('token', token.data.token)
      const user = await currentUser()
      setUserCtx(user.data.userForToken)
      history.push('/home')
    }
    getUserInfo(userId)
  }, [userId, history, setUserCtx])


  return (
    <>
    </>
  )
}

export default ProcessLogin
