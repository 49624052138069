import { useEffect, useState, useContext } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

// Import components
import Navbar from "../components/Navbar";
import FramePDF from "../components/home/FramePDF";
import BoletinSelect from "../components/home/BoletinSelect";

// Import styles
import { BoxHome } from "../styles/home/Home.styled";
import { PDFFrames } from "../styles/home/PDFFrames.styled";

import { convertDate } from "../services/convertDate";
import { getToken } from "../services/stripeServices";
import { Context } from "../context";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { userCtx, handlePaymentStatus } = useContext(Context);
  const history = useHistory();
  const boletines = ["Matutina", "Nocturno", "Manianeras", "Covid-19"];
  const boletinesTitles = ["MATUTINO", "MAÑANERAS", "NOCTURNO", "COVID-19"];

  const fetchBaseURL =
    "https://backend.elmediodemedios.com/archivos?_sort=created_At:DESC&categorias.nombre_eq=";
  const baseViewPDFURL = "https://backend.elmediodemedios.com";

  const [matutinoPDFURL, setmatutinoPDFURL] = useState(null);
  const [nocturnoPDFURL, setnocturnoPDFURL] = useState(null);
  const [mananeraPDFURL, setmananeraPDFURL] = useState(null);
  const [covidPDFURL, setcovidPDFURL] = useState(null);
  const [selectBoletin, setSelectBoletin] = useState("MATUTINO");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadingMananera, setLoadingMananera] = useState(true)
  const [loadingMatutino, setLoadingMatutino] = useState(true)
  const [loadingNocturno, setLoadingNocturno] = useState(true)
  const [loadingCovid, setLoadingCovid] = useState(true)

  const TodayDate = Date(Date.now().toString()).slice(0, 15);
  const { completeDay } = convertDate(TodayDate);

  const fecthData = async (url) => {
    const { data } = await axios.get(`${fetchBaseURL}${url}`);
    return data[0].pdf.url;
  };

  useEffect(() => {
    const data = localStorage.getItem("token") ;
    if(data === null ){
      history.push('/')
    }
    const { userForToken } = jwt_decode(data);
    const setToken = async () => {
      const rawData = await getToken(userForToken._id);
      localStorage.setItem("token", rawData.data.token);
      const info = localStorage.getItem("token");
      const data = jwt_decode(info);

      const path = handlePaymentStatus(data.userForToken);
      if (path !== "/home") history.push(`${path}`);
    };
    setToken();
    const fetch = async () => {
      boletines.forEach((e) => {
        switch (e) {
          case "Matutina":
            fecthData('MDM-Boletines-Matutino')
              .then((res) => {
                setmatutinoPDFURL(baseViewPDFURL + res);
              })
              .catch((error) => {
                console.log(error);
              });
            break;
          case "Nocturno":
            fecthData('MDM-Boletines-Nocturno')
              .then((res) => {
                setnocturnoPDFURL(baseViewPDFURL + res);
              })
              .catch((error) => {
                console.log(error);
              });
            break;
          case "Manianeras":
            fecthData('MDM-Boletines-Mananeras')
              .then((res) => {
                setmananeraPDFURL(baseViewPDFURL + res);
              })
              .catch((error) => {
                console.log(error);
              });
            break;
          case "Covid-19":
            fecthData('MDM-Boletines-Covid-19')
              .then((res) => {
                setcovidPDFURL(baseViewPDFURL + res);
              })
              .catch((error) => {
                console.log(error);
              });
            break;
          default:
            break;
        }
      });
    };

    fetch(); // eslint-disable-next-line
  }, []);

  // This function helps the useEffect handle the resize of the window.
  // It adjusts the component depending on the window width.
  function debounce(fn, miliseconds) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, miliseconds);
    };
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowWidth(window.innerWidth);
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  return (
    <>
      {userCtx !== undefined ? (
        <>
          <Navbar title={selectBoletin} hideProfile={true} hideBack />
          {windowWidth > "1023" ? (
            <BoxHome>
              <h1 className="home__date__l"> {completeDay} </h1>{" "}
              <div className="home__newsletters__l">
                <div className="home__newsletter__l">
                  {" "}
                  {boletinesTitles.map((boletin, index) => (
                    <BoletinSelect
                      key={index}
                      title={boletin}
                      setSelectBoletin={setSelectBoletin}
                      selectBoletin={selectBoletin}
                      setLoadingMananera={setLoadingMananera}
                      setLoadingMatutino={setLoadingMatutino}
                      setLoadingNocturno={setLoadingNocturno}
                      setLoadingCovid={setLoadingCovid}
                    />
                  ))}
                </div>
                <PDFFrames>
                  {selectBoletin === "MATUTINO" && (
                    <FramePDF
                      urlPDF={matutinoPDFURL}
                      title={"MATUTINO"}
                      loading={loadingMatutino}
                      setLoading={setLoadingMatutino}
                    />
                  )
                  }
                  {selectBoletin === "NOCTURNO" && (
                    <FramePDF
                      urlPDF={nocturnoPDFURL}
                      title={"NOCTURNO"}
                      loading={loadingNocturno}
                      setLoading={setLoadingNocturno}
                    />
                  )}
                  {selectBoletin === "MAÑANERAS" && (
                    <FramePDF
                      urlPDF={mananeraPDFURL}
                      title={"MAÑANERAS"}
                      loading={loadingMananera}
                      setLoading={setLoadingMananera}
                    />
                  )
                  }
                  {selectBoletin === "COVID-19" && (
                    <FramePDF
                      urlPDF={covidPDFURL}
                      title={"COVID-19"}
                      loading={loadingCovid}
                      setLoading={setLoadingCovid}
                    />
                  )}
                </PDFFrames>
              </div>
            </BoxHome>
          ) : (
            <BoxHome>
              <h1 className="home__date"> {completeDay} </h1>
              <div className="home__newsletters">
                <div className="newsletter__select">
                  <BoletinSelect
                    title={"MATUTINO"}
                    setSelectBoletin={setSelectBoletin}
                    selectBoletin={selectBoletin}
                    setLoadingMananera={setLoadingMananera}
                    setLoadingMatutino={setLoadingMatutino}
                    setLoadingNocturno={setLoadingNocturno}
                    setLoadingCovid={setLoadingCovid}

                  />
                  <div
                    className={`${boletinesTitles[0] === selectBoletin
                      ? "pdf__active"
                      : "pdf__inactive"
                      }`}
                  >
                    <PDFFrames>
                      {selectBoletin === "MATUTINO" && (
                        <FramePDF urlPDF={matutinoPDFURL} title={"MATUTINO"}
                          loading={loadingMatutino}
                          setLoading={setLoadingMatutino}
                        />
                      )}
                    </PDFFrames>
                  </div>
                </div>
                <div className="newsletter__select">
                  <BoletinSelect
                    title={"MAÑANERAS"}
                    setSelectBoletin={setSelectBoletin}
                    selectBoletin={selectBoletin}
                    setLoadingMananera={setLoadingMananera}
                    setLoadingMatutino={setLoadingMatutino}
                    setLoadingNocturno={setLoadingNocturno}
                    setLoadingCovid={setLoadingCovid}
                  />
                  <div
                    className={`${boletinesTitles[1] === selectBoletin
                      ? "pdf__active__morning"
                      : "pdf__inactive"
                      }`}
                  >
                    <PDFFrames>
                      {selectBoletin === "MAÑANERAS" && (
                        <FramePDF urlPDF={mananeraPDFURL} title={"MAÑANERAS"} loading={loadingMananera}
                          setLoading={setLoadingMananera} />
                      )}
                    </PDFFrames>
                  </div>
                </div>
                <div className="newsletter__select">
                  <BoletinSelect
                    title={"NOCTURNO"}
                    setSelectBoletin={setSelectBoletin}
                    selectBoletin={selectBoletin}
                    setLoadingMananera={setLoadingMananera}
                    setLoadingMatutino={setLoadingMatutino}
                    setLoadingNocturno={setLoadingNocturno}
                    setLoadingCovid={setLoadingCovid}
                  />
                  <div
                    className={`${boletinesTitles[2] === selectBoletin
                      ? "pdf__active"
                      : "pdf__inactive"
                      }`}
                  >
                    <PDFFrames>
                      {selectBoletin === "NOCTURNO" && (
                        <FramePDF urlPDF={nocturnoPDFURL} title={"NOCTURNO"}
                          loading={loadingNocturno}
                          setLoading={setLoadingNocturno}
                        />
                      )}
                    </PDFFrames>
                  </div>
                </div>
                <div className="newsletter__select">
                  <BoletinSelect
                    title={"COVID-19"}
                    setSelectBoletin={setSelectBoletin}
                    selectBoletin={selectBoletin}
                    setLoadingMananera={setLoadingMananera}
                    setLoadingMatutino={setLoadingMatutino}
                    setLoadingNocturno={setLoadingNocturno}
                    setLoadingCovid={setLoadingCovid}
                  />
                  <div
                    className={`select__covid ${boletinesTitles[3] === selectBoletin
                      ? "pdf__active"
                      : "pdf__inactive"
                      }`}
                  >
                    <PDFFrames>
                      {selectBoletin === "COVID-19" && (
                        <FramePDF urlPDF={covidPDFURL} title={"COVID-19"} loading={loadingCovid}
                          setLoading={setLoadingCovid} />
                      )}
                    </PDFFrames>
                  </div>
                </div>
              </div>
            </BoxHome>
          )}
        </>
      ) : (
        history.push("/")
      )
      }
    </>
  );
};

export default Home;
