const ProfileIcon = ({ pushToProfile, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={pushToProfile}
      className={className}
    >
      <path
        d="M12.0001 11.7649C14.155 11.7649 15.9019 10.018 15.9019 7.86315C15.9019 5.70829 14.155 3.96143 12.0001 3.96143C9.84525 3.96143 8.09839 5.70829 8.09839 7.86315C8.09839 10.018 9.84525 11.7649 12.0001 11.7649Z"
        fill="#ABB3BB"
      />
      <path
        d="M18.0667 18.9004H5.97457C5.62447 18.9004 5.33887 18.6148 5.33887 18.2647C5.33887 14.9987 8.01065 12.3223 11.2813 12.3223H12.7554C16.0214 12.3223 18.6978 14.9941 18.6978 18.2647C18.7024 18.6148 18.4168 18.9004 18.0667 18.9004Z"
        fill="#ABB3BB"
      />
    </svg>
  );
};

export default ProfileIcon;
