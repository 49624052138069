import React from "react";

import AlertIconContainer from "../styles/components/AlertIconContainer.styled";
import { ReactComponent as AlertIcon } from "../assets/icons/Alerta.svg";

const AlertIconComponent = () => {
  return (
    <AlertIconContainer>
      <AlertIcon />
    </AlertIconContainer>
  )
};

export default AlertIconComponent;