import styled from "styled-components";
import { colors } from "../variables";

export const NavbarContainer = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);

  .navbar__container {
    display: flex;
    justify-content: space-around;
    width: 99vw;
    .navbar__logo {
      width: 80px;
    }
      .header__arrow {
      fill: ${colors.blueInteraction};
      height: 20px;
      margin-right: 7px;
      width: 12px;
  }
    .navbar__title {
      width: 222px;
      display: flex;
      justify-content: center;
      align-self: center;
      color: ${colors.darkGrayMDM};
      font-family: DM Serif Display;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
    .navbar__icon {
      cursor: pointer;
      width: 76px;
      display: flex;
      justify-content: center;
    }
    .navbar__iconprof {
      cursor: pointer;
      width: 76px;
      display: flex;
      justify-content: center;
      svg {
        path {
          fill: ${colors.blueInteraction};
        }
      }
    }

  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    .navbar__container {
      .navbar__logo {
        width: 120px;
      }
      .navbar__title {
        font-size: 16px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar__container {
      .navbar__logo {
        width: 140px;
      }
      .navbar__title {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    .navbar__container {
      .navbar__logo {
        width: 160px;
      }
      .navbar__title {
        font-size: 24px;
        line-height: 28.13px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .navbar__container {
      .navbar__logo {
        width: 160px;
      }
      .navbar__title {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
`;
