import styled from "styled-components";
import { colors } from "../variables";

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: inherit;
  margin: 10px auto 0;
  /* padding-bottom: 30px; */
  text-align: center;
  width: 327px;

  .succes {
    align-self: center;
    min-height: 38px;
    margin: 20px 0 22px;
    min-width: 38px;
  }

  .success__title,
  .success__description,
  .success__info-title {
    color: ${colors.darkGrayMDM};
  }

  .success__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    margin-bottom: 10px;
  }

  .success__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.95px;
    margin-bottom: 10px;
  }

  .success__info-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
  }

  .success__newsletters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 27px;

    .bulletin__title {
      border-radius: 6px;
      color: white;
      font-family: "Oswald";
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 8px;
      width: 327px;
    }

    #matutino-success {
      background-color: ${colors.yellowMatutino};
    }

    #mañanera-success {
      background-color: ${colors.redMananera};
    }

    #covid-success {
      background-color: ${colors.greenCovid};
    }

    #nocturno-success {
      background-color: ${colors.darkNocturno};
    }
  }

  .success__accept {
    align-self: center;
    background-color: ${colors.blueInteraction};
    border-radius: 50px;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
    min-height: 46px;
    line-height: 24.51px;
    margin-top: 40px;
    width: 193px;
  }

  @media only screen and (max-width: 320px) {
    width: 250px;
    .success__newsletters {
      .bulletin__title {
        width: 250px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 50px auto 0;
    width: 60%;

    .succes {
      min-height: 66px;
      margin: 0 0 25px;
      min-width: 66px;
    }

    .success__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24.51px;
      margin-bottom: 19px;
    }

    .success__description {
      align-self: center;
      margin-bottom: 35px;
      width: 420px;
    }

    .success__info-title {
      font-size: 18px;
      line-height: 24.51px;
    }

    .success__newsletters {
      justify-content: center;
      margin-top: 86px;
      width: 100%;

      .bulletin__title {
        margin: 0 21px 29px 0;
        width: 40%;
      }
    }

    .success__accept {
      /* margin-top: 135px; */
      width: 200px;
    }
  }

  @media only screen and (min-width: 1023px) {
    /* margin: 25px auto 0; */
    width: 60%;

    .succes {
      min-height: 66px;
      margin: 0 0 25px;
      min-width: 66px;
    }

    .success__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24.51px;
      margin-bottom: 19px;
    }

    .success__description {
      align-self: center;
      margin-bottom: 35px;
      width: 420px;
    }

    .success__info-title {
      font-size: 18px;
      line-height: 24.51px;
    }

    .success__newsletters {
      justify-content: center;
      margin-top: 86px;
      width: 100%;

      .bulletin__title {
        margin: 0 21px 29px 0;
        width: 40%;
      }
    }

    .success__accept {
      /* margin-top: 100px; */
      width: 200px;
    }
  }
`;
