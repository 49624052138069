import styled from "styled-components";
import { colors } from "../variables";

const ProfileConfigurationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .profile__config-form {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  label {
    font-size: 14px;
    color: ${colors.darkGrayMDM};
  }

  input {
    height: 47px;
    padding: 15px;
    margin: 5px 0 25px;
    border-radius: 2px;
    border: 0.5px solid ${colors.grayBorder};
  }

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  form > div:nth-child(4) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile__config-birthday {
    align-self: center;
  }

  .profile__config-button {
    width: 100%;
    min-height: 40px;
    color: white;
    background-color: ${colors.blueInteraction};
    font-weight: bold;
    font-size: 18px;
    border: none;
    margin-top: 50px;
    border-radius: 2px;
  }

  @media only screen and (min-width: 992px) {
    .profile__config-form {
      width: 45%;
    }

    .profile__config-button {
      align-self: center;
      width: 50%;
    }
  }
`;

export default ProfileConfigurationContainer;