import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// Import components
import FormUserConfig from '../components/profileConfig/FormUserConfig'

//Import context
import { Context } from '../context/index';


const ProfileConfig = () => {

  const history = useHistory();
  const { userCtx } = useContext(Context);

  return (
    <>
      {
        userCtx !== undefined ?
          <div>
            <FormUserConfig />
          </div> :
          history.push('/')
      }
    </>
  )
}

export default ProfileConfig