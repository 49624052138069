import styled from "styled-components";

export const PageAuth = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: start;
  width: 100%;
  height: ${props => props.height};
`;
