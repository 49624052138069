import React from "react";

import { ReactComponent as ArrowIcon } from "../assets/icons/arrow_left_icon.svg";

import HeaderContainer from "../styles/components/HeaderContainer.styled";

const Header = ({ history, path = '/profile' }) => {

  return (
    <HeaderContainer>
      <div
        className="header__back"
        onClick={() => history.goBack()}
      >
        <ArrowIcon className="header__arrow" />
        <p>Regresar</p>
      </div>
    </HeaderContainer>
  );
};

export default Header;
