import React from "react";

import Navbar from "../components/Navbar";

import SuccessfulPaymentContainer from "../styles/SuccessfulPayment/SuccessfulPayment.styled";
import { ReactComponent as SuccessIcon } from "../assets/icons/Correcto.svg";

const SuccessfulPayment = ({ history }) => {
  const pushToHome = () => {
    history.push("/home");
  };

  return (
    <>
      <Navbar />
      <SuccessfulPaymentContainer>
        <div className="success-message__container">
          <SuccessIcon className="success-img" />
          <p className="success-title">
            <strong>Tu pago se realizó exitosamente</strong>
          </p>
          <p className="success-message">
            Ahora puedes seguir disfrutando de las notas más relevantes del día
          </p>
          <button className="success__continue" onClick={pushToHome}>
            Aceptar
          </button>
        </div>
      </SuccessfulPaymentContainer>
    </>
  );
};

export default SuccessfulPayment;
