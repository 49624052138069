import { createContext, useState } from 'react';

import { withRouter } from 'react-router-dom';

// Import service
import { currentUser } from '../services/auth'

import moment from "moment";

export const Context = createContext();

const CtxProvider = ({ children }) => {

  const getUserData = async () => {
    const user = await currentUser()
    setUserCtx(user.data.userForToken)
  }

  const [userCtx, setUserCtx] = useState(() => getUserData())

  const loginCtx = userData => setUserCtx(userData)
  const logoutCtx = () => setUserCtx(null)

  const handlePaymentStatus = (user) => {
    if (user) {
      const { plan, stripe } = user;
      if (plan.paymethStatus === 'active' || plan.paymethStatus === 'trialing') {
        return '/home';
      } else if (plan.paymethStatus === 'incomplete_expired') {
        return '/subscription';
      } else if (plan.paymethStatus === 'canceled') {
        if (moment(stripe.nextCharge * 1000).isAfter(moment())) {
          return '/login';
        } else {
          console.log("check moment");
          return "/home";
        }
      } else {
        return '/paymentFailure';
      }
    }
  }

  // console.log('userCTX', userCtx)

  return (
    <Context.Provider value={{
      userCtx,
      setUserCtx,
      loginCtx,
      logoutCtx,
      handlePaymentStatus
    }}>
      { children}
    </Context.Provider>
  )
}

export default withRouter(CtxProvider);
