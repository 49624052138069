import styled from "styled-components";
import { colors } from "../variables";

export const FormAuth = styled.div `
  margin: 0 auto;
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    /* margin-bottom: 50px; */
  }

  form > div {
    margin-top: 25px;
  }

  input {
    border-radius: 6px;
    border: 0.5px solid ${colors.grayBorder};
    height: 46px;
    margin-top: 5px;
    padding: 15px;
    width: 100%;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
  }

  .password {
    display: flex;
    align-items: center;

    input {
      border-right: none;
      border-radius: 6px 0 0 6px;
    }

    .eye {
      border: 0.5px solid ${colors.grayBorder};
      border-left: none;
      border-radius: 0 6px 6px 0;
      height: 46px;
      padding-right: 5px;
      cursor: pointer;
      margin-top: 5px;
    }

    svg {
      path {
        fill: ${colors.lightGray};
      }
    }
  }

  p {
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    color: ${colors.blueInteraction};
    margin-top: 36px;
  }

  .error {
    color: ${colors.redMananera};
    font-size: 12px;
    text-align: left;
    margin: 0;
  }

  .confirm__container {
    display: flex;
    justify-content: center;
    margin-top: 0;

    .confirm__button {
      background-color: ${colors.blueInteraction};
      border-radius: 50px;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-top: 50px;
      min-height: 40px;
      width: 200px;
    }

    .confirm__button:disabled {
      background-color: ${colors.blueInteractionInactive};
    }
  }

  .terms {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 10px;
      margin-right: 10px;
      border: none;
    }

    .terms__link {
      color: ${colors.blueInteraction};
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 280px;
    margin-top: 12px;

    .formregister__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      /* margin-bottom: 12px; */
    }

    .formregister__input {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .formregister__input__error {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .terms {
      width: 280px;
      .terms__label {
        font-size: 12px;
        width: 268px;
      }
    }

    .confirm__container {
      display: flex;
      justify-content: center;
      .confirm__button {
        margin-top: 39px;
        width: 193px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 0;
    justify-content: start;
    height: inherit;
    margin-top: 75px;

    .formregister__title {
      font-size: 18px;
      line-height: 24.51px;
      margin-bottom: 89px;
    }

    .formregister__input {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .formregister__input__error {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .terms {
      width: 100%;
      margin-top: 0;
      .terms__label {
        font-size: 13px;
      }
    }

    .confirm__container {
      display: flex;
      justify-content: center;
      .confirm__button {
        margin-top: 80px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    height: inherit;
    justify-content: start;
    margin-top: 80px;
    padding-top: 0;

    .formregister__input__error {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
`;