import styled from "styled-components";
import { colors } from "../variables";

const ProfileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
  padding: 90px 5% 0;
  width: 100vw;

  .profile__info__main {
    width: 300px;
    .profile__info__container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 42px;
      width: 100%;

      .profile__img__container {
        border: 1px solid black;
        height: 155px;
        margin: 0 0 0 auto;
        overflow: hidden;
        width: 155px;

        .profile__img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .profile__info {
        color: ${colors.darkGrayMDM};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 0 0 15px;
        margin-right: auto;
        width: 155px;

        .profile__username {
          font-family: "Crimson Pro";
          font-size: 17px;
          line-height: 18.75px;
          margin-bottom: 9px;
        }

        .profile__email-container {
          background: #eee;
          border-radius: 23px;
          width: 140px;
          .profile__textinfo {
            color: ${colors.mediumGrayMDM};
            font-size: 12px;
            font-weight: 400;
            line-height: 16.34px;
            text-align: center;
          }
        }
      }
    }

    .profile__billing {
      color: ${colors.mediumGrayMDM};
      font-size: 11px;
      padding: 0 14px 45px 14px;
      text-align: center;
      width: 100%;

      &__text {
        font-size: 11px;
        font-weight: 400;
        line-height: 16.34px;
      }

      &__email {
        color: ${colors.blueInteraction};
        font-size: 11px;
        line-height: 17.7px;
        font-weight: 600;
      }

      &__email:visited {
        color: ${colors.blueInteraction};
      }
    }
  }

  .profile__logout {
    color: ${colors.darkGrayMDM};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 135px;

    .profile__info__main {
      width: 650px;
      .profile__info__container {
        justify-content: center;
        margin-bottom: 135px;

        .profile__img__container {
          height: 200px;
          margin: 0;
          width: 200px;
        }

        .profile__info {
          justify-content: flex-end;
          width: 35%;
          padding-left: 0;
          margin: 0 0 0 25px;

          .profile__username {
            font-size: 24px;
            line-height: 25px;
          }

          .profile__textinfo {
            font-size: 14px;
            line-height: 19.07px;
          }
        }
      }

      .profile__billing {
        &__text {
          font-size: 18px;
          font-weight: 400;
          line-height: 24.51px;
        }

        &__email {
          font-size: 18px;
          font-weight: 600;
          line-height: 24.51px;
        }
      }
    }

    .profile__logout {
      margin-bottom: 60px;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 100px;

    .profile__info__main {
      width: 780px;

      .profile__info__container {
        justify-content: center;
        margin-bottom: 60px;

        .profile__img__container {
          height: 175px;
          margin: 0 25px 0 60px;
          width: 175px;
        }

        .profile__info {
          width: 250px;
          padding-left: 45px;
          margin: 0 0 0 0;

          .profile__username {
            font-size: 24px;
            line-height: 33px;
          }

          .profile__textinfo {
            font-size: 14px;
            line-height: 19.07px;
          }
        }
      }

      .profile__billing {
        padding-bottom: 124px;

        &__text {
          font-size: 18px;
          font-weight: 400;
          line-height: 24.51px;
        }

        &__email {
          font-size: 18px;
          font-weight: 600;
          line-height: 24.51px;
        }
      }
    }

    .profile__logout {
      padding-bottom: 30px;
    }
  }
`;

export default ProfileContainer;
