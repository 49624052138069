import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

//import services 
import { recoverPassword } from '../services/auth';

//import styles
import { RecoverPasswordEmailForm } from '../styles/RecoverPassword/RecoverPasswordEmail';
import Header from '../components/Header'

const RecoverPassword = () => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [emailStatus, setEmailStatus] = useState('')

    const handleInput = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await recoverPassword(email);
        setEmailStatus(res.data.msg);
    }

    return (
        <>
            <Header history={history} path={'/login'}/>
            <RecoverPasswordEmailForm>
                <h2 className="email-login__title">Recupera tu contraseña</h2>
                <form className="email-login__form">
                    <div className="input__container">
                        <label className="email-login__label" htmlFor="email">Correo</label>
                        <input className="email-login__input" type="text" name="email" value={email} onChange={handleInput} />
                    </div>
                    <p> {emailStatus} </p>
                    <div className="confirm__container">
                        <button className="confirm__button" onClick={handleSubmit}>Enviar correo</button>
                    </div>
                </form>
            </RecoverPasswordEmailForm>
        </>
    )
}

export default RecoverPassword
