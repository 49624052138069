import styled from 'styled-components'
import { colors } from '../variables'

export const Back = styled.div`
  padding: 40px 0 0 150px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.blueInteraction};
  cursor: pointer;

  .arrow {
    width: 12px;
    height: 20px;
    margin-right: 7px;
    fill: ${colors.blueInteraction};
  }

  @media only screen and (max-width: 600px) {
    padding: 68px 0 0 30px;
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    padding: 80px 0 0 60px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 80px 0 0 90px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    padding: 80px 0 0 120px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 80px 0 0 120px;
  }
`