import styled from 'styled-components'

export const PDFFrames = styled.div `
  transform: translate(0, -50px);
  transition: ease-in-out 1s;
  width: 100%;
  height: 100%;
  padding: 0 24px;

    iframe {
        width: 100%;
        height: 100%;
        margin: 50px 0 0;
      }

  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    iframe {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1443px) {
    padding: 0;

    iframe {
      height: 575px;
      padding-top: 12px;
    }
  }

  @media only screen and (min-width: 1444px) {
    margin-left: 10px;

    iframe {
      height: 90%;
      padding-top: 12px;
    }
  }
`

export const OpacityLoader = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: black;
  opacity: 0.5;
  margin-top:50px;
  height: 100%;
  width: 89%;
  margin-top: 50px;

  @media (min-width: 481px) and (max-width: 768px) {
    width: 92.5%; 
  }
  

  @media only screen and (min-width: 992px) and (max-width: 1024px) {   
      width: 100%;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1443px) {
    width: 100%;
  }

  @media only screen and (min-width: 1444px) {
    width: 100%;
  }
    
`