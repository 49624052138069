import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// Import services
import { logout } from '../../services/auth'

import { Context } from '../../context'


const LogoutButton = () => {
  const { logoutCtx } = useContext(Context)
  const history = useHistory()
  
  const logoutAll = () => {
    logout()
    history.push('/')
    logoutCtx()
    localStorage.clear();
  }

  return (
    <p onClick={logoutAll}>Cerrar sesión</p>
  )
}

export default LogoutButton
