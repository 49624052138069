import axios from 'axios'

const baseURL = process.env.REACT_APP_SERVER_ENDPOINT

const service = axios.create({ baseURL, withCredentials: true })

export const signup = async userData => {
    const data = await service.post('/boletines/signup', userData)
    return data
}

export const login = async userData => {
    const token = await service.post('/boletines/login', userData)
    localStorage.setItem('token', token.data.token)
    return token
}

export const currentUser = async() => {
    const token = localStorage.getItem('token')
    const user = await service.get('/boletines/currentuser', {
        headers: {
            'authorization': token ? token : ''
        }
    })
    return user
}

export const subscription = async(userId, paymentId, planData) => {
    const data = await service.put(`/boletines/paymenths/${userId}/${paymentId}`, planData)
    return data
}

export const findUser = async userId => {
    const token = await service.get(`/boletines/finduser/${userId}`)
    return token
}

export const logout = async() => {
    await service.get('/boletines/logout')
    return true
}

export const changePassword = async(userId, data) => {
    return await service.post(`/boletines/changePassword/${userId}`, data);
}

export const recoverPassword = async(email) => {
    return await service.post(`/boletines/recoverPassword/${email}`);
}

export const setForgetPassword = async(id, token, data) => {
    return await service.post(`boletines/newPassword/${id}/${token}`, data);
}