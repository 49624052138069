import { useEffect, useContext, useState } from "react";
import { Context } from "../context/index";
import jwt_decode from "jwt-decode";
import Loader from "react-loader-spinner";

// Import services
import {
  getPaymentMethods,
  getInvoices,
  cancelSubscription,
  setDefaultPayment,
  getToken,
} from "../services/stripeServices";
import { convertDate } from "../services/convertDate";

// Import components
import Navbar from "../components/Navbar";
import CardDefaultPayment from "../components/CardDefaultPayment";

// Import styles
import { ManageAccountContainer } from "../styles/ManageAccount/ManageAccount.styled";

// Import icons
import { ReactComponent as StripeIcon } from "../assets/icons/Stripe_(company)-Powered-by-Stripe-Logo.wine.svg";
import { ReactComponent as Download } from '../assets/icons/downlowadPDF.svg'

import moment from "moment";
moment.locale('es')

const ManageAccount = ({ history }) => {
  const { userCtx, handlePaymentStatus } = useContext(Context);
  const [paymentCards, setPaymentCards] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [newSubsCharge, setNewSubsCharge] = useState(null);
  const [loadingPDF, setloadingPDF] = useState(false)

  useEffect(() => {
    const tokenInfo = localStorage.getItem("token");
    const { userForToken } = jwt_decode(tokenInfo);
    const setToken = async () => {
      const rawData = await getToken(userForToken._id);
      localStorage.setItem("token", rawData.data.token);
      const info = localStorage.getItem("token");
      const data = jwt_decode(info);
      console.log(data.userForToken);

      const path = handlePaymentStatus(data.userForToken);
      if (path !== "/home") history.push(`${path}`);
    };
    setToken();
    if (userCtx.active) {
      const getMethods = async () => {
        const { data } = await getPaymentMethods(userCtx.stripe.customerId);
        const invoicesInfo = await getInvoices(userCtx.stripe.customerId);
        setPaymentCards(data);
        setInvoices(invoicesInfo.data);
      };
      getMethods();
      const token = localStorage.getItem("token");
      const { userForToken } = jwt_decode(token);
      if (userForToken.stripe.nextCharge) {
        const { completeDay } = convertDate(
          new Date(userForToken?.stripe.nextCharge * 1000).toString().slice(0, 16)
        );
        const i = completeDay.indexOf(" ");
        setNewSubsCharge(completeDay.slice(i));
      }
    }
  }, [userCtx, history, handlePaymentStatus]);

  const cancelPlan = async () => {
    await cancelSubscription(userCtx.stripe.subscriptionId, userCtx._id);
  };

  const handleDefaultPayment = async (id) => {
    const {
      stripe: { customerId },
    } = userCtx;
    await setDefaultPayment(customerId, id);
    const { data } = await getPaymentMethods(customerId);
    setPaymentCards(data);
  };

  const pushToCancelAccount = () => {
    history.push("/cancelAccount");
  }

  // {true && (
  //   <AddPaymentModal>
  //     <PaymentMethod />
  //   </AddPaymentModal>
  // )}

  console.log(invoices);

  return (
    <>
      {userCtx !== undefined ? (
        <>
          <Navbar title='Gestionar Cuenta' hideProfile={false} />
          <ManageAccountContainer>
            <div className="manage-account__cards">
              <div className="account-card__info">
                <div className="card__top-border"></div>
                <h4 className="card__title">Plan actual</h4>
                <p className="card__text">El medio de Medios</p>
                <p className="card__price">
                  {userCtx.plan?.period === "MONTHLY"
                    ? "$499.00 por mes"
                    : "$4,799.00 por año"}
                </p>
                {userCtx.stripe?.nextSubscriptionPlan && (
                  <>
                    <h4 className="card__title">Plan próximo</h4>
                    <p className="card__price">
                      {userCtx.stripe.nextSubscriptionPlan === "MONTHLY"
                        ? "$499.00 por mes"
                        : "$4,799 por año"}
                    </p>
                    <p className="card__text">
                      Empezará a cobrarse el {newSubsCharge}
                    </p>
                  </>
                )}
                <button
                  className="card__change-plan"
                  onClick={() => history.push("/changeplan")}
                >
                  Cambiar plan
                </button>
                <button className="card__cancel-plan" onClick={cancelPlan}>
                  Cancelar plan
                </button>
              </div>
              <div div className="payment-cards">
                <div className="card__top-border"></div>
                <div className="payment__header">
                  <StripeIcon className="payment__stripe-icon" />
                  <h4 className="card__title">Método de pago</h4>
                </div>
                <div className="card__container">
                  {paymentCards.map((el, i) => (
                    <CardDefaultPayment
                      key={i}
                      cardInfo={el}
                      handleDefaultPayment={handleDefaultPayment}
                    />
                  ))}
                </div>
                <button
                  className="card__new-payment-method"
                  onClick={() => history.push("/newPaymentMethod")}
                >
                  Añadir nuevo
                </button>
              </div>
            </div>
            <div className="receipts-record">
              <h1 className="receipts-record__title">Historial de Recibos</h1>
              {invoices.map((el) => (
                <div className="receipt__container" key={el.created}>
                  <p className="receipt__date">
                    {
                      moment(new Date(el.created * 1000)).format('DD MMM YYYY')
                    }
                  </p>
                  <p className="receipt__quantity">
                    $
                      {el.amount_paid
                      .toString()
                      .split("")
                      .slice(el.amount_paid.length, -2)
                      .join("")}
                  </p>
                  <p className="receipt__quote">EL MEDIO DE MEDIOS</p>
                  <a href={el.invoice_pdf} className="receipt__brand"
                    onClick={() => {
                      setloadingPDF(true)
                      setTimeout(() => {
                        setloadingPDF(false)
                      }, 5000);
                    }}
                  >
                    {loadingPDF ? (
                      <Loader
                        type="TailSpin"
                        color="#235789"
                        height={30}
                        width={30}
                      />
                    ) : (
                      <Download />
                    )}
                  </a>
                </div>
              ))}
            </div>
            <div className="account__cancel" onClick={pushToCancelAccount}>
              <p>Cancelar mi cuenta</p>
            </div>
          </ManageAccountContainer>
        </>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default ManageAccount;
