import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

// Import hooks
import useInput from '../../hooks/useInput'
import { Context } from '../../context'

// Import services
import { signup } from '../../services/auth'

// Impor styles
import { FormAuth } from '../../styles/auth/FormAuth.styled'

// Import icons
import { ReactComponent as EyeIcon } from '../../assets/icons/Eyeopen.svg'
import { ReactComponent as EyeIconClose } from "../../assets/icons/Eyeclose.svg";


const FormSignup = ({ setLoading }) => {
  const history = useHistory()
  const { setUserCtx } = useContext(Context)
  const [error, setError] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const [typePassword, setTypePassword] = useState("password")

  // Inputs value
  const nameInput = useInput('')
  const emailInput = useInput('')
  const passwordInput = useInput('')
  const passwordConfirmInput = useInput('')
  const [termsInput, setTermsInput] = useState(false)

  // Submit
  const handleSubmit = async e => {
    e.preventDefault()
    if (passwordInput.value !== passwordConfirmInput.value) {
      setErrorPassword("Las contraseñas no coinciden")
    } else if (!/(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{6,}/g.test(passwordInput.value)) {
      setErrorPassword("Mínimo seis carácteres, una mayúscula y un número.")
    } else {
      try {
        setLoading(true)
        const user = await signup({
          name: nameInput.value,
          email: emailInput.value,
          password: passwordInput.value
        })
        setUserCtx(user.data)
        history.push('/subscription')
      }
      catch (error) {
        console.log(error);
        setLoading(false)
        if (error.response.data.message === 'Por favor proporciona todos los datos')
          setErrorPassword(error.response.data.message)
        if (error.response.data.message === 'El email ya está registrado')
        setError(error.response.data.message)
      }
    }
  }

  const handleChange = () => {
    setError(null)
    setErrorPassword(null)
  }

  const viewPassword = () => {
    if (typePassword === "password") {
      setTypePassword("text")
    } else {
      setTypePassword("password")
    }
  }

  return (
    <FormAuth>
      <h2 className="formregister__title">Regístrate</h2>
      <form onSubmit={handleSubmit} onChange={handleChange}>
        <div className="formregister__input">
          <label htmlFor="name" >Correo</label>
          <input type="name" name="name" id="name" placeholder="Ingresa tu correo" {...nameInput} />
        </div>
        <div className={`${error ? "formregister__input__error" : "formregister__input"}`}>
          <label htmlFor="email" >Confirmar correo</label>
          <input type="email" name="email" id="email" placeholder="Confirma tu correo"  {...emailInput} />
        </div>
        {error && <p className="error" >{error}</p>}
        <div className="formregister__input">
          <label htmlFor="password" >Contraseña</label>
          <div className="password">
            <input type={typePassword} name="password" id="password" placeholder="Ingresa tu contraseña" {...passwordInput} />
            {typePassword === "password" ?
              <EyeIcon className="eye" onClick={viewPassword} />
              :
              <EyeIconClose className="eye" onClick={viewPassword} />
            }
          </div>
        </div>
        <div className={`${error ? "formregister__input__error" : "formregister__input"}`}>
          <label htmlFor="passwordConfirm" >Confirma tu contraseña</label>
          <div className="password">
            <input type={typePassword} name="passwordConfirm" id="passwordConfirm" placeholder="Confirma tu contraseña" {...passwordConfirmInput} />
            {typePassword === "password" ?
              <EyeIcon className="eye" onClick={viewPassword} />
              :
              <EyeIconClose className="eye" onClick={viewPassword} />
            }
          </div>
        </div>
        {errorPassword && <p className="error"> {errorPassword}</p>}
        <div className="terms" >
          <input className="terms" type="checkbox" id="terms" name="terms" onClick={() => setTermsInput(!termsInput)} />
          <label className="terms__label" htmlFor="terms"> He leído y acepto los <span className="terms__link">Términos y Condiciones</span></label>
        </div>
        <div className="confirm__container">
          <button className="confirm__button" type="submit" disabled={!termsInput}>Continuar</button>
        </div>
      </form>
    </FormAuth>
  )
}

export default FormSignup
