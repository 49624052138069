import styled from "styled-components";
import { colors } from "../variables";

const HeaderContainer = styled.div`
  align-items: center;
  background:
    linear-gradient(
      0deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(252, 252, 255, 1) 10%,
      rgba(255, 255, 255, 1) 100%
    );
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 95px 10% 50px;
  top: 55px;
  width: 100%;

  .header__back {
    color: ${colors.blueInteraction};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: bold;
  }

  .header__arrow {
    fill: ${colors.blueInteraction};
    height: 20px;
    margin-right: 7px;
    width: 12px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 94px;
    padding: 95px 20%;
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    margin-bottom: 94px;
    padding: 95px 20%;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 44px;
    padding: 95px 20% 50px;
  }
`;

export default HeaderContainer;