import styled from "styled-components";
import { colors } from "../variables";

export const PaymentPage = styled.div`
  width: 370px;
  margin: 0 auto;

  h2 {
    font-weight: 600;
    font-size: 18px;
    color: ${colors.darkGrayMDM};
    margin-bottom: 60px;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 60px;
    span {
      color: ${colors.blueInteraction};
      font-weight: bold;
    }
  }

  label {
    font-size: 14px;
    color: ${colors.darkGrayMDM};
  }

  input,
  .card {
    height: 47px;
    padding: 15px;
    margin: 5px 0 25px;
    border-radius: 2px;
    border: 0.5px solid ${colors.grayBorder};
  }

  .card-half {
    width: 175px;
  }

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  form > div:nth-child(4) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    width: 100%;
    min-height: 40px;
    color: white;
    background-color: ${colors.blueInteraction};
    font-weight: bold;
    font-size: 18px;
    border: none;
    margin-top: 50px;
    border-radius: 2px;
  }

  button:disabled {
    background-color: ${colors.blueInteractionInactive};
  }

  .error {
    text-align: justify;
    color: ${colors.blueInteraction};
  }

  @media only screen and (max-width: 600px) {
    width: 280px;
    margin-top: 52px;
    padding: 0 0 63px 0;

    .paymentmethod__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 41px;
    }

    .paymentmethod__selectedplan {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.95px;
      margin-bottom: 23px;
      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 20.95px;
      }
    }

    .paymentmethod__form {
      display: flex;
      flex-direction: column;

      .paymentmethod__input {
        margin-top: 0;
        margin-bottom: 20px;

        label {
          font-weight: 700;
          margin-bottom: 7px;
        }

        input {
          border-radius: 6px;
          margin: 0;
        }
      }

      .card {
        border-radius: 6px;
        margin: 7px 0 20px 0;
      }

      .paymentmethod__card-security {
        .card {
          border-radius: 6px;
          margin: 7px 0 20px 0;
        }

        .card-half {
          border-radius: 6px;
          width: 130px;
        }
      }

      .paymentmethod__submit {
        align-self: center;
        border-radius: 50px;
        margin-top: 30px;
        width: 193px;
      }
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 350px;
    margin-top: 52px;
    padding: 0 0 63px 0;

    .paymentmethod__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 41px;
    }

    .paymentmethod__selectedplan {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.95px;
      margin-bottom: 23px;
      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 20.95px;
      }
    }

    .paymentmethod__form {
      display: flex;
      flex-direction: column;

      .paymentmethod__input {
        margin-top: 0;
        margin-bottom: 20px;

        label {
          font-weight: 700;
          margin-bottom: 7px;
        }

        input {
          border-radius: 6px;
          margin: 0;
        }
      }

      .card {
        border-radius: 6px;
        margin: 7px 0 20px 0;
      }

      .paymentmethod__card-security {
        .card {
          border-radius: 6px;
          margin: 7px 0 20px 0;
        }

        .card-half {
          border-radius: 6px;
          width: 170px;
        }
      }

      .paymentmethod__submit {
        align-self: center;
        border-radius: 50px;
        margin-top: 30px;
        width: 193px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    width: 420px;
    margin-top: 80px;
    /* padding: 0 0 63px 0; */

    .paymentmethod__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24.51px;
      margin-bottom: 35px;
    }

    .paymentmethod__selectedplan {
      font-size: 16px;
      font-weight: 400;
      line-height: 23.94px;
      margin-bottom: 35px;
      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 23.94px;
      }
    }

    .paymentmethod__form {
      display: flex;
      flex-direction: column;

      .paymentmethod__input {
        margin-top: 0;
        margin-bottom: 20px;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 19.07px;
          margin-bottom: 7px;
        }

        input {
          border-radius: 6px;
          font-size: 16px;
          line-height: 23.94px;
          margin: 0;
        }
      }

      label {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
      }

      .card {
        border-radius: 6px;
        margin: 7px 0 20px 0;
      }

      .paymentmethod__card-security {
        margin-top: 20px;
        .card {
          border-radius: 6px;
          margin: 7px 0 20px 0;
        }

        .card-half {
          border-radius: 6px;
          width: 200px;
        }
      }

      .paymentmethod__submit {
        align-self: center;
        border-radius: 50px;
        margin-top: 30px;
        width: 193px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 370px;
    /* margin-top: 15px;
    padding: 0 0 63px 0; */

    .paymentmethod__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24.51px;
      margin-bottom: 61px;
    }

    .paymentmethod__selectedplan {
      font-size: 16px;
      font-weight: 400;
      line-height: 23.94px;
      margin-bottom: 32px;
      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 23.94px;
      }
    }

    .paymentmethod__form {
      display: flex;
      flex-direction: column;

      .paymentmethod__input {
        margin-top: 0;
        margin-bottom: 40px;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 19.07px;
          margin-bottom: 7px;
        }

        input {
          border-radius: 6px;
          font-size: 16px;
          line-height: 23.94px;
          margin: 0;
        }
      }

      label {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
      }

      .card {
        border-radius: 6px;
        margin: 7px 0 20px 0;
      }

      .paymentmethod__card-security {
        margin-top: 40px;
        .card {
          border-radius: 6px;
          margin: 7px 0 20px 0;
        }

        .card-half {
          border-radius: 6px;
          width: 175px;
        }
      }

      .paymentmethod__submit {
        align-self: center;
        border-radius: 50px;
        margin-top: 60px;
        width: 200px;
      }
    }
  }
`;

export const AddPaymentPage = styled.div`
  height: inherit;
  padding: 100px 0 30px;
  width: 370px;
  margin: 0 auto;

  .profile-header {
    align-items: center;
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(252, 252, 255, 1) 10%,
      rgba(255, 255, 255, 1) 100%
    );
    display: flex;
    height: 55px;
    justify-content: space-between;
    top: 55px;
    width: 100%;
  }

  .profile-back {
    color: ${colors.blueInteraction};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
  }

  .profile-arrow {
    fill: ${colors.blueInteraction};
    height: 20px;
    margin-right: 7px;
    width: 12px;
  }

  .new-payment__description {
    .new-payment__title {
      color: ${colors.darkGrey};
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 10px;
      text-align: center;
    }

    .new-payment__instruction {
      color: ${colors.darkGrey};
      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      margin-bottom: 55px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    color: ${colors.darkGrayMDM};
    margin-bottom: 60px;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 60px;
    span {
      color: ${colors.blueInteraction};
      font-weight: bold;
    }
  }

  label {
    font-size: 14px;
    color: ${colors.darkGrayMDM};
  }

  input,
  .card {
    height: 47px;
    padding: 15px;
    margin: 5px 0 25px;
    border-radius: 6px;
    border: 0.5px solid ${colors.grayBorder};
  }

  .card-half {
    width: 175px;
  }

  .form-container {
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
    }

    .paymentmethod__input {
      display: flex;
      flex-direction: column;
    }
  }

  form > div:nth-child(4) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    align-self: center;
    background-color: ${colors.blueInteraction};
    border-radius: 50px;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-top: 50px;
    min-height: 40px;
    width: 193px;
  }

  button:disabled {
    background-color: ${colors.blueInteractionInactive};
  }

  .error {
    text-align: justify;
    color: ${colors.blueInteraction};
  }

  @media only screen and (max-width: 600px) {
    width: 280px;
    padding: 100px 0 63px 0;

    .paymentmethod__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 30px;
    }

    .paymentmethod__selectedplan {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.95px;
    }

    .paymentmethod__input {
      margin-top: 0;
      margin-bottom: 22px;
      input {
        margin: 0;
      }
    }

    .card {
      margin: 0 0 22px 0;
    }

    .card-half {
      width: 125px;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    padding: 100px 0 30px;

    .paymentmethod__title {
      font-size: 15px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 60px;
    }

    .paymentmethod__selectedplan {
      font-size: 15px;
      font-weight: 400;
      line-height: 20.95px;
    }

    .paymentmethod__input {
      margin-top: 0;
      margin-bottom: 22px;
      input {
        margin: 0;
      }
    }

    .card {
      margin: 0 0 22px 0;
    }

    .card-half {
      width: 180px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 100px 0 30px;

    .paymentmethod__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 60px;
    }

    form {
      align-self: center;
      width: 300px;
    }

    .paymentmethod__selectedplan {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.95px;
    }

    .paymentmethod__input {
      margin-top: 0;
      margin-bottom: 22px;
      input {
        margin: 0;
      }
    }

    .card {
      margin: 0 0 22px 0;
    }

    .card-half {
      width: 140px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    padding: 100px 0 30px;
    width: 480px;

    .new-payment__description {
      .new-payment__title {
        font-size: 16px;
        margin-bottom: 25px;
      }

      .new-payment__instruction {
        font-size: 16px;
      }
    }

    .paymentmethod__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 19.07px;
      margin-bottom: 60px;
    }

    form {
      align-self: center;
      width: 380px;
    }

    .paymentmethod__selectedplan {
      font-size: 18px;
      font-weight: 400;
      line-height: 20.95px;
    }

    .paymentmethod__input {
      margin-top: 0;
      margin-bottom: 22px;
      input {
        margin: 0;
      }
    }

    .card {
      margin: 0 0 22px 0;
    }

    .card-half {
      width: 180px;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 100px 0 30px;
    width: 480px;
    .new-payment__description {
      margin-bottom: 68px;
      .new-payment__title {
        font-size: 16px;
        margin-bottom: 25px;
      }

      .new-payment__instruction {
        font-size: 16px;
      }
    }

    form {
      align-self: center;
      width: 380px;
    }
  }
`;
