import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../context';
import jwt_decode from "jwt-decode";

// Import services
import { getPaymentMethods, setDefaultPayment, getToken} from '../services/stripeServices';
import { changePlan } from '../services/stripeServices';
// import { convertDate } from "../services/convertDate";


// Import components
import Navbar from '../components/Navbar';
import CardDefaultPayment from '../components/CardDefaultPayment';

// Import styles
import ChangePlanContainer from '../styles/ManageAccount/ChangePlan.styled';

// Import icons
// import { ReactComponent as ArrowIcon } from '../assets/icons/arrow_left_icon.svg';
import { ReactComponent as SuccesIcon } from '../assets/icons/Correcto.svg';
import { ReactComponent as Alert } from '../assets/icons/Alerta.svg';


import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const ChangePlan = ({ history }) => {
  const { userCtx, setUserCtx, handlePaymentStatus } = useContext(Context)
  const [paymentCards, setPaymentCards] = useState([]);
  const [periodEnd, setPeriodEnd] = useState(null)
  const [planPeriod, setplanPeriod] = useState(null)
  const [success, setSuccess] = useState(false)


  useEffect(() => {
    const data = localStorage.getItem('token');
    const { userForToken } = jwt_decode(data);
    const setToken = async () => {
      const rawData = await getToken(userForToken._id);
      localStorage.setItem('token', rawData.data.token);
      const info = localStorage.getItem('token')
      const data = jwt_decode(info);

      const path = handlePaymentStatus(data.userForToken);
      if (path !== '/home')
        history.push(`${path}`)
    }
    setToken()

    if (userCtx.active) {
      const getMethods = async () => {
        const { data } = await getPaymentMethods(userCtx.stripe.customerId)
        setPaymentCards(data)
      }
      getMethods()
      const dateD = moment(userCtx?.stripe.startCharge * 1000).format(' DD [de] MMMM [del] YYYY')
      // const { completeDay } = convertDate(new Date(userCtx?.stripe.nextCharge * 1000).toString().slice(0, 16))
      setPeriodEnd(dateD)
    }
    if (userCtx.stripe.nextSubscriptionPlan) {
      setplanPeriod(userCtx.stripe.nextSubscriptionPlan)
    } else {
      setplanPeriod(userCtx.plan.period)
    }

  }, [userCtx, handlePaymentStatus, history])

  const handleDefaultPayment = async () => {
    const { stripe: { customerId } } = userCtx;
    await setDefaultPayment(customerId, userCtx._id);
    const { data } = await getPaymentMethods(customerId);
    setPaymentCards(data);
  }

  const handleChangePlan = async () => {
    const { data } = await changePlan(`PRICE_${planPeriod === 'MONTHLY' ? 'YEARLY' : 'MONTHLY'}_ID`, userCtx._id)
    setUserCtx(data)
    setSuccess(true)
  }

  return (
    <>
      <Navbar title='Cambiar plan'/>
      <ChangePlanContainer>
        {/* <div className="change-plan__header">
          <div onClick={() => history.push('/manageAccount')} className="change-plan__back">
            <ArrowIcon className="change-plan__arrow" />
            <p>Regresar</p>
          </div>
        </div> */}
        {!success &&
          <div className="change-plan__info-container">
            <Alert className="alert__icon" />
            <h2 className="change-plan__title">Estás a punto de cambiar tu plan</h2>
            <p className="change-plan__text"> Tu <span>Plan {planPeriod === 'MONTHLY' ? 'Mensual' : 'Anual'}</span>,
            se mantendrá hasta el {periodEnd}. Posterior a esta fecha comenzará de forma automática
            tu nuevo <span>Plan {planPeriod === 'MONTHLY' ? 'Anual' : 'Mensual'}</span> y se
            realizará tu primer pago.</p>
            <div className="change-plan__cost">
              <p className="change-plan__text">Plan {planPeriod === 'MONTHLY' ? 'Anual' : 'Mensual'}</p>
              <p className="change-plan__price">{planPeriod === 'MONTHLY' ? '$4, 499.00' : '$499.00'} MXN</p>
            </div>
            <div className="change-plan__payment-method">
              <h4 className="payment-method__text">Estos son tus métodos de pago registrados:</h4>
                {paymentCards.map((card, i) => (
                  <CardDefaultPayment
                    key={i}
                    cardInfo={card}
                    showexp={false}
                    handleDefaultPayment={handleDefaultPayment} />
                ))}
              <Link className="payment-method__add" to="/newPaymentMethod">Añadir nuevo método de pago</Link>
            </div>
            <button className="change-plan__button" onClick={handleChangePlan}>Cambiar plan</button>
          </div>
        }
        {success &&
          <div className="change-plan__success">
            <SuccesIcon className="success__icon" />
            <h4 className="success__title">¡Operación exitosa!</h4>
            <p className="success__text">El cambio de plan mensual se realizó con éxito.
            Recuerda que el siguiente cargo se realizará
            de forma automática el:</p>
            <p className="success__date">{periodEnd}</p>
            <button className="success__button" onClick={() => history.push('/home')}>Aceptar</button>
          </div>}
      </ChangePlanContainer>
    </>
  )
}

export default ChangePlan
