import React from "react";

import InputAccountCancelContainer from "../styles/components/InputAccountCancelContainer.styled";

const InputAccountCancel = ({ cancelInputValue, setCancelInputValue, cancelConfirmed, setCancelConfirmed }) => {
 
  const changeCancelInputValue = (e) => {
    const { value } = e.target;
    setCancelInputValue(value);

    if (value === "CANCELAR") {
      setCancelConfirmed(true);
    } else {
      setCancelConfirmed(false);
    }
  };

  return (
    <InputAccountCancelContainer>
      <label htmlFor="Cancelar" className="account-cancel__label">
        Ingresa la palabra CANCELAR para confirmar
      </label>
      <input
        className={cancelConfirmed ? "border__green" : ""}
        type="cancel"
        name="cancel"
        id="cancel"
        onChange={changeCancelInputValue}
        value={cancelInputValue}
      />
    </InputAccountCancelContainer>
  );
};

export default InputAccountCancel;
