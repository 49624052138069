import styled from "styled-components";
import { colors } from "../variables";

const ProfileOptions = styled.div`
  margin-bottom: 31px;

  .profile__options {
    justify-content: space-between;
    cursor: pointer;
    display: flex;
    padding: 20px 0;
    width: inherit;
    .options__text {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
    }

    .options__icon {
      align-self: center;
      fill: ${colors.darkGrayMDM};
      height: 12px;
      width: 7px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin-bottom: 134px;

    .profile__options {
      margin: 0 auto;
      padding: 20px 0;
      width: 427px;
      .options__text {
        font-size: 18px;
        line-height: 24.51px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 75px;

    .profile__options {
      margin-bottom: 10px;
      padding: 20px 17% 20px 25%;
      .options__text {
        font-size: 15px;
        line-height: 20.43px;
      }
    }
  }
`;

export default ProfileOptions;
