// Import styles
import { SubscriptionPage, Switch } from '../../styles/auth/SubscriptionPage.styled'


const SubscriptionPlan = ({ monthlyPlans, setMonthlyPlans, setBoletines }) => {

  return (
    <SubscriptionPage>
      <h1 className="subscription__title">BOLETINES</h1>
      <h2 className="subscription__subtitle">Nuestros boletines exhaustivos directo en tu correo electrónico</h2>
      <div className="subscription__details">
        <p className={`subscription__period ${monthlyPlans && 'bold'}`}>Mensual</p>
        <Switch>
          <input
            type='checkbox'
            value={monthlyPlans}
            checked={!monthlyPlans}
            onChange={() => setMonthlyPlans(!monthlyPlans)}/>
          <span className='slider round'/>
        </Switch>
        <p className={`subscription__period-anual ${!monthlyPlans && 'bold'}`}>Anual</p>
      </div>
      {monthlyPlans && <p className="subscription__price">$499.00 MXN</p>}
      {!monthlyPlans && <p className="subscription__price">$4,799.00 MXN</p>}
      <button className="subscription__select-button" onClick={() => setBoletines(false)}>Seleccionar</button>
    </SubscriptionPage>
  )
}

export default SubscriptionPlan;