import { useContext, useEffect } from "react";
import { Context } from "../context/index";
import jwt_decode from "jwt-decode";

//import services
import { getToken } from "../services/stripeServices";

// import components
import ProfileOption from "../components/perfil/ProfileOption";
import LogoutButton from "../components/auth/LogoutButton";
import Navbar from "../components/Navbar";

// Import styles
import ProfileContainer from "../styles/profilePage/Profile.styled";
import ProfileOptions from "../styles/profilePage/ProfileOptions.styled";

// Import icons
import ProfilePic from "../assets/images/Profile.png";

const Profile = ({ history }) => {

  const profileOptionsForSocialLogin = [
    {
      text: "Editar mi perfil",
      pushTo: "profile/config",
    },
    {
      text: "Gestionar cuenta",
      pushTo: "manageAccount",
    },
  ];

  const profileOptionsForEmailLogin = [
    {
      text: "Editar mi perfil",
      pushTo: "profile/config",
    },
    {
      text: "Cambiar contraseña",
      pushTo: "profile/changePassword",
    },
    {
      text: "Gestionar cuenta",
      pushTo: "manageAccount",
    },
  ];

  const { userCtx, handlePaymentStatus } = useContext(Context);

  useEffect(() => {
    const data = localStorage.getItem("token");
    const { userForToken } = jwt_decode(data);
    const setToken = async () => {
      const rawData = await getToken(userForToken._id);
      localStorage.setItem("token", rawData.data.token);
      const info = localStorage.getItem("token");
      const data = jwt_decode(info);

      const path = handlePaymentStatus(data.userForToken);
      if (path !== "/home") history.push(`${path}`);
    };
    setToken();
  }, [handlePaymentStatus, history]);

  return (
    <>
      {userCtx !== undefined ? (
        <>
          <Navbar title="Perfil" hideProfile={true}/>
          <ProfileContainer>
            <div className="profile__info__main">
              <div className="profile__info__container">
                <div className="profile__img__container">
                  <img
                    className="profile__img"
                    src={userCtx?.photo ? userCtx.photo : ProfilePic}
                    alt="Foto del usuario"
                  />
                </div>
                <div className="profile__info">
                  <h3 className="profile__username">{userCtx?.name}</h3>
                  <div className="profile__email-container">
                    <p className="profile__textinfo">{userCtx?.email}</p>
                  </div>
                </div>
              </div>

              <ProfileOptions>
                {userCtx.hasOwnProperty("photo")
                  ? profileOptionsForSocialLogin.map((el, index) => (
                    <ProfileOption
                      text={el.text}
                      key={index}
                      redirect={el.pushTo}
                      history={history}
                    />
                  ))
                  : profileOptionsForEmailLogin.map((el, index) => (
                    <ProfileOption
                      text={el.text}
                      key={index}
                      redirect={el.pushTo}
                      history={history}
                    />
                  ))}
              </ProfileOptions>
              <div className="profile__billing">
                <p className="profile__billing__text">¿Necesitas Factura?</p>
                <p className="profile__billing__text">
                  Escríbenos a{" "}
                  <a
                    className="profile__billing__email"
                    href="https://google.com"
                  >
                    facturacion@elmediodemedios.com
                  </a>
                </p>
              </div>
            </div>
            <div className="profile__logout">
              <LogoutButton className="logout" />
            </div>
          </ProfileContainer>
        </>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default Profile;
