import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

//import services
import { setForgetPassword } from '../services/auth';

//import styles
import ProfileConfigurationContainer from "../styles/profilePage/ProfileConfiguration.styled";
import { ReactComponent as EyeIcon } from '../assets/icons/eye_icon.svg';
import Navbar from '../components/Navbar';
import Header from '../components/Header';

const SetNewPassword = () => {

    const history = useHistory();

    const { id, token } = useParams();
    const [newPasswordInput, setNewPasswordInput] = useState('');
    const [confirmNewPasswordInput, setConfirmNewPasswordInput] = useState('')
    const [passwordStatus, setPasswordStatus] = useState('');
    const [show, setShow] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!/(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{6,}/g.test(newPasswordInput)) {
            setPasswordStatus('Mínimo seis carácteres, una mayúscula y un número.')
        } else {
            const body = {
                newPasswordInput,
                confirmNewPasswordInput
            }
            const res = await setForgetPassword(id, token, body);
            setPasswordStatus(res.data.msg)
            setTimeout(() => {
                window.close()
            }, 3000);
        }
    }

    return (
        <>
            <Navbar />
            <Header history={history} path={'/login'} />
            <ProfileConfigurationContainer>
                <form className="profile__config-form">
                    <div>
                        <label htmlFor="password">Nueva Contraseña</label>
                        <input type={show ? 'text' : 'password'} name="password" value={newPasswordInput} onChange={(e) => setNewPasswordInput(e.target.value)} />
                    </div>
                    <label htmlFor="password">Confirma tu Contraseña</label>
                    <input type={show ? 'text' : 'password'} name="password" value={confirmNewPasswordInput} onChange={(e) => setConfirmNewPasswordInput(e.target.value)} />
                    <EyeIcon className="eye" onClick={() => setShow(!show)} />
                    <button className="profile__config-button" onClick={handleSubmit}>Guardar contraseña</button>
                </form>
                <p> {passwordStatus} </p>
            </ProfileConfigurationContainer>
        </>
    )
}

export default SetNewPassword;
