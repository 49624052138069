import styled from "styled-components";
import { colors } from "../variables";
import { css } from "@emotion/core";

export const override = css`
  display: block;
  margin: 2;
  border-color: white;
`;

export const PaymentFailureContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 95px 5% 0;
  width: 100vw;
  .back {
    align-self: flex-start;
    color: ${colors.blueInteraction};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    .arrow {
      width: 12px;
      height: 20px;
      margin-right: 7px;
      fill: ${colors.blueInteraction};
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 300px;
    .alert {
      align-self: center;
      height: 57px;
      margin-bottom: 13px;
      width: 57px;
    }
    .success__icon {
      align-self: center;
      height: 38.23px;
      width: 38.23px;
    }
    h1 {
      font-family: Open Sans;
      font-weight: 700;
      font-size: 14px;
      line-height: 19.07px;
      text-align: center;
      height: 34px;
    }
    p {
      font-family: Open Sans;
      font-weight: 400;
      font-size: 12px;
      line-height: 16.34px;
      text-align: center;
      margin-bottom: 15px;
    }
    .second-p {
      margin-bottom: 10px;
      text-align: center;
    }
    a {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 13px;
      color: #235789;
    }

    .add__new-payment {
      align-self: center;
      background: #fff;
      border: none;
      margin-bottom: 100px;
      &:focus {
        outline: none;
      }
      &:disabled {
        cursor: not-allowed;
        background: grey;
      }

      p {
        color: ${colors.blueInteraction};
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .methods {
    width: 100%;
    min-height: 88px;
    max-height: 100px;
    margin-bottom: 20px;
    padding: 0 30px;
    overflow: scroll;
  }
  .loading {
    animation: loading 2s linear infinite;
    border-radius: 5px;
  }
  @keyframes loading {
    0% {
      background-color: white;
    }
    50% {
      background-color: lightgray;
    }
    100% {
      background-color: white;
    }
  }
  .errorMessage {
    color: red;
    font-size: 14px;
    font-weight: 600;
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    padding: 95px 10% 75px;
    .content {
      display: flex;
      flex-direction: column;
      width: 350px;
      .add__new-payment {
        width: 350px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 95px 10% 75px;
    .back {
      margin-bottom: 100px;
    }
    .content {
      width: 425px;
      .success__icon {
        margin-bottom: 20px;
      }
      h1,
      p,
      .second-p {
        font-size: 16px;
        line-height: 24.51px;
      }
      .second-p {
        margin-bottom: 30px;
      }

      .blue,
      .gray {
        font-size: 14px;
        line-height: 19.07px;
      }

      .add__new-payment {
        align-self: center;
        width: 350px;
      }
    }
    .methods {
      margin-bottom: 40px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    padding: 95px 10% 75px;
    .back {
      margin-bottom: 100px;
    }
    .content {
      width: 425px;
      .success__icon {
        margin-bottom: 20px;
      }
      h1,
      p,
      .second-p {
        font-size: 18px;
        line-height: 24.51px;
      }
      .second-p {
        margin-bottom: 30px;
      }

      .blue,
      .gray {
        font-size: 14px;
        line-height: 19.07px;
      }

      .add__new-payment {
        align-self: center;
        width: 350px;
        p {
          font-size: 14px;
        }
      }
    }
    .methods {
      margin-bottom: 40px;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 95px 20% 75px;
    .back {
      margin-bottom: 50px;
    }
    .content {
      width: 640px;
      .success__icon {
        margin-bottom: 20px;
      }
      h1,
      p,
      .second-p {
        font-size: 18px;
        line-height: 24.51px;
      }
      h1 {
        margin-bottom: 10px;
      }
      .second-p {
        margin-bottom: 60px;
      }

      .blue,
      .gray {
        font-size: 14px;
        line-height: 19.07px;
      }

      .add__new-payment {
        align-self: center;
        width: 350px;
        p {
          font-size: 14px;
        }
      }
    }
    .methods {
      margin-bottom: 40px;
    }
  }
`;

export const Method = styled.button`
  border: none;
  background: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  .checkbox {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  p {
    font-family: OpenSans;
    font-weight: 700 !important;
    font-size: 14px;
    margin-bottom: 0 !important;
    margin-right: 21px;
  }
  .card__brand {
    margin-right: 0;
  }
  .blue {
    color: #235789;
    margin-left: 10px;
  }
  .gray {
    color: gray;
    margin-left: 10px;
  }
  .card {
    min-width: 28px;
    min-height: 20px;
    width: 28px;
    height: 20px;
    background: lightgray;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    svg {
      height: 18px;
      width: 18px;
    }

    .card__brand {
      svg {
        padding-top: 5px;
      }
    }
  }
`;

export const ButtonCont = styled.button`
  background: #235789;
  border-radius: 50px;
  border: none;
  height: 46px;
  margin-bottom: 30px;
  width: 193px;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background: #235789;
  }
  .buttonText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
`;
