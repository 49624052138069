import styled from "styled-components";
import { colors } from "../variables";

export const SubscriptionPage = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-family: "Oswald";
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
    color: ${colors.darkGrayMDM};
  }

  > div {
    margin: 100px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: " Oswald";
    font-weight: 300;
    font-size: 40px;

    p {
      margin: 0 50px;
    }
  }

  .bold {
    font-weight: bold;
  }

  > p {
    font-family: "Oswald";
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 100px;
  }

  button {
    width: 65%;
    min-height: 40px;
    color: white;
    background-color: ${colors.blueInteraction};
    font-weight: bold;
    font-size: 18px;
    border: none;
    border-radius: 2px;
  }

  .subscription__title {
      font-family: DM Serif Display;
      font-style: normal;
  }

  @media only screen and (max-width: 600px) {
    width: 280px;
    margin-top: 55px;
    padding: 0 0 63px 0;

    .subscription__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    .subscription__subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
    }

    .subscription__details {
      margin-top: 50px;
      margin-bottom: 70px;

      .subscription__period {
        font-size: 30px;
        font-weight: 700;
        line-height: 40.85px;
        margin: 0;
      }

      .subscription__period-anual {
        font-size: 30px;
        font-weight: 300;
        line-height: 40.85px;
        margin: 0;
      }
    }

    .subscription__price {
      font-size: 24px;
      font-weight: 700;
      line-height: 32.68px;
    }

    .subscription__select-button {
      border-radius: 50px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24.51px;
      height: 46px;
      width: 193px;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    width: 550px;
    margin-top: 52px;
    padding: 0 0 63px 0;

    .subscription__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 32.9px;
      margin-bottom: 20px;
    }

    .subscription__subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.51px;
      margin: 0 auto;
      width: 50%;
    }

    .subscription__details {
      margin-top: 50px;
      margin-bottom: 70px;

      .subscription__period {
        font-size: 30px;
        font-weight: 700;
        line-height: 40.85px;
        margin: 0;
      }

      .subscription__period-anual {
        font-size: 30px;
        font-weight: 300;
        line-height: 40.85px;
        margin: 0;
      }
    }

    .subscription__price {
      font-size: 24px;
      font-weight: 700;
      line-height: 32.68px;
    }

    .subscription__select-button {
      border-radius: 50px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24.51px;
      height: 46px;
      width: 193px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 600px;
    margin-top: 170px;
    padding: 0 0 63px 0;

    .subscription__title {
      font-size: 40px;
      font-weight: 400;
      line-height: 54.84px;
      margin-bottom: 20px;
    }

    .subscription__subtitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 32.68px;
      margin: 0 auto;
      width: 60%;
    }

    .subscription__details {
      margin-top: 50px;
      margin-bottom: 51px;

      .subscription__period {
        font-size: 40px;
        font-weight: 700;
        line-height: 54.47px;
        margin: 0 59px 0 0;
      }

      .subscription__period-anual {
        font-size: 40px;
        font-weight: 300;
        line-height: 54.47px;
        margin: 0 0 0 59px;
      }
    }

    .subscription__price {
      font-size: 40px;
      font-weight: 700;
      line-height: 54.47px;
      margin-bottom: 191px;
    }

    .subscription__select-button {
      border-radius: 50px;
      font-size: 18px;
      font-weight: 700;
      height: 48px;
      line-height: 24.51px;
      width: 200px;
    }
  }

  @media only screen and (min-width: 1024px) {
    max-width: 600px;
    margin-top: 85px;
    padding: 0 0 63px 0;

    .subscription__title {
      font-size: 40px;
      font-weight: 400;
      line-height: 54.84px;
      margin-bottom: 20px;
    }

    .subscription__subtitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 32.68px;
      margin: 0 auto;
      width: 85%;
    }

    .subscription__details {
      margin-top: 50px;
      margin-bottom: 51px;

      .subscription__period {
        font-size: 40px;
        font-weigth: bold;
        line-height: 54.47px;
        margin: 0 59px 0 0;
      }

      .subscription__period-anual {
        font-size: 40px;
        font-style: ligth;
        line-height: 54.47px;
        margin: 0 0 0 59px;
      }
    }

    .subscription__price {
      font-size: 40px;
      font-weight: 700;
      line-height: 54.47px;
      margin-bottom: 152px;
    }

    .subscription__select-button {
      border-radius: 50px;
      font-size: 18px;
      font-weight: 700;
      height: 48px;
      line-height: 24.51px;
      width: 200px;
    }
  }
`;

export const Switch = styled.label`
  margin: 0 10px;
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.blueInteraction};
    transition: 0.4s;
  }
  .slider::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: ${colors.blueInteractionInactive};
  }
  input:focus + .slider {
    box-shadow: 0 0 1px ${colors.blueInteractionInactive};
  }
  input:checked + .slider::before {
    transform: translateX(24px);
  }
  .slider.round {
    border-radius: 24px;
  }
  .slider.round::before {
    border-radius: 50%;
  }
`;
