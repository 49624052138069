import styled from "styled-components";
import { colors } from "../variables";

const CancelAccountContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  padding-top: 125px;
  width: 100vw;

  .cancel-info__container {
    display: flex;
    flex-direction: column;
    width: 280px;

    .cancel-title__container {
      align-self: center;
      width: 280px;
      .cancel__title {
        color: ${colors.darkGrey};
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 54px;
        width: inherit;
      }
    }
  }

  .cancel-success__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 125px 0 150px 0;
    width: 90%;

    svg {
      height: 38.23px;
      width: 38.23px;
    }

    .cancel-success__title {
      color: ${colors.darkGrey};
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
      margin-top: 11px;
      text-align: center;
      width: 288px;
    }
  }

  .confirm__container {
    display: flex;
    justify-content: center;
    margin-top: 0;

    .confirm__button {
      background-color: ${colors.blueInteraction};
      border-radius: 50px;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin: 50px 0 30px 0;
      min-height: 40px;
      width: 200px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .cancel-info__container {
      width: 450px;
      .cancel-title__container {
        align-self: center;
        width: 305px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .cancel-info__container {
      width: 550px;
      .cancel-title__container {
        align-self: center;
        width: 390px;
        .cancel__title {
          font-size: 16px;
        }
      }
    }

    .cancel-success__container {
      svg {
        height: 45px;
        width: 45px;
      }

      .cancel-success__title {
        font-size: 16px;
      }
    }
  }
`;

export default CancelAccountContainer;
