import { useContext, useState } from 'react';
import { attachPaymentMethod } from '../services/stripeServices'

// Import stipe elements
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'

import { ReactComponent as ArrowIcon } from '../assets/icons/arrow_left_icon.svg';

// Import hooks
import useInput from '../hooks/useInput'
import { Context } from '../context/index'

// Import styles
import { AddPaymentPage } from '../styles/auth/PaymentPage.styled'
import Navbar from '../components/Navbar';

// Crete stripe promise
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)



const AddNewPaymentMethod = ({ history }) => {

  const { userCtx } = useContext(Context)

  const dictionaryError = {
    "Your card was declined.": "Lo sentimos, la tarjeta que intentas registrar fue declinada. Revisa que todo esté en orden con tu institución financiera o intenta registrando otra tarjeta.",
    "Your card's security code is incorrect.": "Lo sentimos, el código de seguridad de la tarjeta es incorrecto. Revisa que lo hayas registrado correctamente o intenta registrando otra tarjeta.",
    "Your card has insufficient funds.": "Lo sentimos, los fondos de la tarjeta son insuficientes. Revisa que todo esté en orden con tu institución financiera o  intenta registrando otra tarjeta.",
    "Your card has expired.": "Lo sentimos, la tarjeta que intentas registrar está expirada. Por favor intenta registrando otra tarjeta.",
    "An error occurred while processing your card. Try again in a little bit.": "Lo sentimos, ocurrió un error inesperado. Por favor intenta hacer tu registro mas tarde.",
  }

  const nameInput = useInput('')
  const [numbreStatus, setNumbreStatus] = useState(false)
  const [CVVStatus, setCVVStatus] = useState(false)
  const [periodStatus, setPeriodStatus] = useState(false)
  const [error, setError] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()
    const cardNumber = elements.getElement(CardNumberElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: nameInput.value
      }
    })
    if (error) {
      console.log([error]);
    } else {
      try {
        await attachPaymentMethod({ customerID: userCtx.stripe.customerId, paymethMethodID: paymentMethod.id })
        history.push({
          pathname: '/paymentFailure',
          state: { newPaymentMethodAdded: true }
        })
      } catch (error) {
        if (error.response.data)
          setError(dictionaryError[error.response.data.raw.message] ? dictionaryError[error.response.data.raw.message] : "Ha ocurrido un error")
        else
          console.log(error)
      }
    }
  }

  return (
    <>
      {
        userCtx !== undefined ?
          <>
            <Navbar title='Metodo de pago' />
            <AddPaymentPage>
              <section className='profile-header'>
                <div onClick={() => history.goBack()} className="profile-back">
                  <ArrowIcon className="profile-arrow" />
                  <p>Regresar</p>
                </div>
              </section>
              <section className="new-payment__description">
                <h1 className="new-payment__title">Nuevo método de pago</h1>
                <p className="new-payment__instruction">Por favor ingresa los datos de tu nuevo método de pago</p>
              </section>
              <section className='form-container'>
                <form onSubmit={handleSubmit}>
                  <div className="paymentmethod__input">
                    <label htmlFor="name">Nombre del titular</label>
                    <input type="text" name="name" id="name" placeholder="Ingresa el nombre del titular" {...nameInput} style={{ width: '100%' }} />
                  </div>
                  <label>Número de tarjeta</label>
                  <CardNumberElement className="card" onChange={e => setNumbreStatus(e.complete)} />
                  <div>
                    <div>
                      <label>CVV</label>
                      <CardCvcElement className="card card-half" onChange={e => setCVVStatus(e.complete)} />
                    </div>
                    <div>
                      <label>Vigencia</label>
                      <CardExpiryElement className="card card-half" onChange={e => setPeriodStatus(e.complete)} />
                    </div>
                  </div>
                  {error && <p className="error">{error}</p>}
                  <button type="submit" disabled={nameInput.value === '' || !numbreStatus || !CVVStatus || !periodStatus}>Continuar</button>
                </form>
              </section>
            </AddPaymentPage>
          </> :
          history.push('/')
      }
    </>
  )
}

const PaymentMethod = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <AddNewPaymentMethod {...props} />
    </Elements>
  )
}

export default PaymentMethod;
