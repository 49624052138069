import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router'
import CtxProvider from './context/index';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
// import * as serviceWorker from './serviceWorker';


const WithContext = () => {
  return (
    <BrowserRouter>
      <CtxProvider>
        <GlobalStyles />
        <Router />
      </CtxProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <WithContext />,
  document.getElementById('root')
);

// if ('serviceWorker' in navigator) {
//   caches.keys().then(function (cacheNames) {
//     cacheNames.forEach(function (cacheName) {
//       caches.delete(cacheName);
//     });
//   });
// }

// serviceWorker.register();