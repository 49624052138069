import { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Import components
import SubscriptionPlan from '../../components/auth/SubscriptionPlan'
import Payment from '../../components/auth/Payment'

// Import styles
import { PageAuth } from '../../styles/auth/PageAuth.styled'
import { Back } from '../../styles/components/Back.styled'

// Import icons
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow_left_icon.svg'

const Subscription = () => {
  const history = useHistory()

  const [monthlyPlans, setMonthlyPlans] = useState(true)
  const [loading, setLoading] = useState(false)
  const [boletines, setBoletines] = useState(true)


  const returnTo = () => {
    if (boletines) {
      history.goBack();
    } else {
      setBoletines(true)
    }
  }

  return (
    <>
      <Back onClick={returnTo}>
        <ArrowIcon className="arrow" />
        <p>Regresa</p>
      </Back>
      <PageAuth>
        {boletines &&
          <SubscriptionPlan
            monthlyPlans={monthlyPlans}
            setMonthlyPlans={setMonthlyPlans}
            setBoletines={setBoletines} />}
        {!boletines &&
          <>
            <Payment
              monthlyPlans={monthlyPlans}
              loading={loading}
              setLoading={setLoading} />
          </>}
      </PageAuth>
    </>
  )
}

export default Subscription