import ProfileConfigurationContainer from "../../styles/profilePage/ProfileConfiguration.styled";

const ProfileConfiguration = ({
  handleProfileChangeSubmit,
  nameInput,
  emailInput,
  birthdayInput,
}) => {
  return (
    <ProfileConfigurationContainer>
      <form
        className="profile__config-form"
        onSubmit={handleProfileChangeSubmit}
      >
        <label htmlFor="name">Nombre</label>
        <input
          type="name"
          name="name"
          id="name"
          value={nameInput.value}
          {...nameInput}
        />
        <label htmlFor="email">Correo</label>
        <input
          type="email"
          name="email"
          id="email"
          value={emailInput.value}
          {...emailInput}
        />
        <label htmlFor="birthday">Fecha de nacimiento</label>
        <input
          className="profile__config-birthday"
          type="date"
          name="birthday"
          id="birthday"
          value={birthdayInput.value}
          {...birthdayInput}
        />
        <button className="profile__config-button">Guardar cambios</button>
      </form>
    </ProfileConfigurationContainer>
  );
};

export default ProfileConfiguration;
