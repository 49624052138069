import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_ENDPOINT;

const MY_SERVICE = axios.create({
    baseURL,
    withCredentials: true
})

export const getPaymentMethods = async customerID => {
    return await MY_SERVICE.get(`/boletines/paymenths/list/${customerID}`);
}
export const attachPaymentMethod = async data => {
    return await MY_SERVICE.post('/boletines/paymenths/attach', data);
}
export const setDefaultPayment = async(customerID, paymentMethodID) => {
    return await MY_SERVICE.get(`/boletines/paymenths/default/${customerID}/${paymentMethodID}`);
}
export const retryPaymenth = async(subscriptionID, userID) => {
    return await MY_SERVICE.get(`/boletines/paymenths/retry/${subscriptionID}/${userID}`);
}
export const getInvoices = async customerID => {
    const invoices = await MY_SERVICE.get(`/boletines/getInvoices/${customerID}`);
    return invoices
}
export const changePlan = async(planChange, userId) => {
    const data = await MY_SERVICE.get(`/boletines/updatePlan/${planChange}/${userId}`);
    return data
}
export const cancelSubscription = async(subsId, userId) => {
    return await MY_SERVICE.put(`/boletines/cancelSubscription/${subsId}/${userId}`);
}
export const getSubscriptions = async customerId => {
    const data = await MY_SERVICE.get(`/boletines/subscription/${customerId}`);
    return data
}
export const getToken = async userId => {
    return await MY_SERVICE.get(`/boletines/getToken/${userId}`);
}