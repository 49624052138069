import { useContext } from "react";
import { useHistory } from "react-router-dom";

// Impor hooks
import { Context } from "../../context";

// Import styles
import { Back } from "../../styles/components/Back.styled";
import { Success } from "../../styles/auth/Succes.styled";

// Import icons
import { ReactComponent as SuccesIcon } from "../../assets/icons/success_icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow_left_icon.svg";

import moment from 'moment'
moment.locale('es')

const SuccessPayment = () => {
  const history = useHistory();
  const { userCtx } = useContext(Context);
  let plan;
  // eslint-disable-next-line
  let periodStart;
  // eslint-disable-next-line
  let periodEnd;

  if (userCtx.plan) {
    plan = userCtx.plan.period;
    // eslint-disable-next-line
    periodStart = new Date(userCtx?.stripe.nextCharge * 1000)
      .toString()
      .slice(0, 16);
      // eslint-disable-next-line
    periodEnd = new Date(userCtx?.stripe.nextCharge * 1000)
      .toString()
      .slice(0, 16);
  }

  return (
    <>
      <Back>
        <ArrowIcon className="arrow" />
        <p>Regresa</p>
      </Back>
      <Success>
        <SuccesIcon className="succes" />
        <h2 className="success__title">Tu pago se realizó exitosamente</h2>
        <p className="success__description">
          Tu periodo de 7 días gratis ha comenzado, tu pago de{" "}
          {plan === "MONTHLY" ? "$499.00 " : "4,799.00 "}
          se iniciará a partir del {moment().format('DD [de] MMMM [del] YYYY')} y hasta el {moment(userCtx?.stripe.nextCharge * 1000).format('DD [de] MMMM [del] YYYY')}.
        </p>
        <h2 className="success__info-title">
          Tu pago te incluye el acceso a los siguientes boletines:
        </h2>
        <div className="success__newsletters">
          <h3 className="bulletin__title" id="matutino-success">
            MATUTINO
          </h3>
          <h3 className="bulletin__title" id="mañanera-success">
            MAÑANERA
          </h3>
          <h3 className="bulletin__title" id="covid-success">
            COVID-19
          </h3>
          <h3 className="bulletin__title" id="nocturno-success">
            NOCTURNO
          </h3>
        </div>
        <button className="success__accept" onClick={() => history.push("/home")}>Aceptar</button>
      </Success>
    </>
  );
};

export default SuccessPayment;
