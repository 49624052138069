import styled from "styled-components";
import { colors } from "../variables";

import MatutinoImage from "../../assets/images/Matutino.png";
import MañaneraImage from "../../assets/images/Mañanera.png";
import NocturnoImage from "../../assets/images/Nocturno.png";
import CovidImage from "../../assets/images/Covid.png";

export const SelectBoletinBtn = styled.div`
  width: 100%;
  padding: 0 24px;

  .boletin__title {
    border-radius: 6px;
    color: white;
    margin: 13px 0 9px;
    padding: 0 40px;
    text-align: center;
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
  }

  .selected {
    font-size: 48px;
    line-height: 71px;
    height: 100px;
    padding-top: 15px;
  }

  .selected__mananera {
    padding: 15px 0 30px;
  }

  #MATUTINO {
    background-color: ${colors.yellowMatutino};
  }

  #MATUTINO.selected {
    background-image: url(${MatutinoImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  #NOCTURNO {
    background-color: ${colors.darkNocturno};
  }

  #NOCTURNO.selected {
    background-image: url(${NocturnoImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  #MAÑANERAS {
    background-color: ${colors.redMananera};
  }

  #MAÑANERAS.selected {
    background-image: url(${MañaneraImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  #COVID-19 {
    background-color: ${colors.greenCovid};
    background-size: 100%;
  }

  #COVID-19.selected {
    background-image: url(${CovidImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .boletin__title {
      font-size: 60px;
      height: 102px;
      line-height: 88.92px;
      margin: 13px 0 0;
    }

    .selected {
      font-size: 90px;
      height: 196px;
      line-height: 142px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1443px) {
    width: 425px;
    .boletin__title {
      font-size: 36px;
      line-height: 49px;
      padding: 0;
      width: 100%;
      max-width: 566px;
    }

    .selected {
      font-size: 59px;
      line-height: 88px;
      height: 150px;
      padding: 35px 0 0;
      text-align: center;
      width: 100%;
      max-width: 566px;
    }
  }

  @media only screen and (min-width: 1444px) {
    padding: 0;
    width: 425px;
    .boletin__title {
      font-size: 36px;
      line-height: 49px;
      padding: 0;
      width: 100%;
      max-width: 566px;
    }

    .selected {
      font-size: 59px;
      line-height: 88px;
      height: 150px;
      padding: 35px 0 0;
      text-align: center;
      width: 100%;
      max-width: 566px;
    }
  }
`;
