//import styles
import { OpacityLoader } from '../../styles/home/PDFFrames.styled'
//Spiner
import Loader from "react-loader-spinner";

const FramePDF = ({ urlPDF, title, loading, setLoading }) => {

  const handleSetLoading = () => {
    setLoading(false)
  }

  return (
    <>
      {loading ? (
        <OpacityLoader>
          <Loader
            type="ThreeDots"
            color="#FFFF"
            height={100}
            width={100}
          />
        </OpacityLoader>
      ) : null}
      <iframe
        src={`https://docs.google.com/gview?url=${urlPDF}&embedded=true`}
        frameBorder="0" onLoad={handleSetLoading} title={`PDF de ${title}`} />
    </>
  )
}

export default FramePDF;