import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Import context
import { Context } from "../context/index";

// Services
// import { currentUser } from "../services/auth";

import Navbar from "../components/Navbar";

//import styles and icons
import {
  PaymentFailureContainer,
  Method,
  ButtonCont,
  override,
} from "../styles/PaymentFailure/PaymentFailure.styled";
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow_left_icon.svg";
import { ReactComponent as Checked } from "../assets/icons/checked_icon.svg";
import { ReactComponent as Unchecked } from "../assets/icons/unchecked_box_icon.svg";
import { ReactComponent as VisaIcon } from "../assets/icons/visa_icon.svg";
import { ReactComponent as MastercardIcon } from "../assets/icons/mastercard_icon.svg";
import { ReactComponent as SuccessIcon } from "../assets/icons/Correcto.svg";
import AlertIcon from "../components/AlertIcon";
import BeatLoader from "react-spinners/BeatLoader";

//import services
import {
  getPaymentMethods,
  setDefaultPayment,
  retryPaymenth,
} from "../services/stripeServices";

const PaymentFailure = ({ location }) => {
  const history = useHistory();

  const { userCtx } = useContext(Context);
  const [paymentCards, setPaymentCards] = useState([]);
  const [showErrorLegend, setShowErrorLegend] = useState(true);
  const [showChangeLegend, setShowChangeLegend] = useState(false);
  const [changeDefault, setchangeDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPaymenMethodAddedLegend, setNewPaymentMethodAddedLegend] = useState(
    false
  );

  useEffect(() => {
    if (userCtx.active) {
      const getMethods = async () => {
        const { data } = await getPaymentMethods(userCtx.stripe.customerId);
        setPaymentCards(data);
      };
      getMethods();
    }

    if (location.state !== undefined) {
      const {
        state: { newPaymentMethodAdded },
      } = location;

      setNewPaymentMethodAddedLegend(newPaymentMethodAdded);
      setShowErrorLegend(false);
    }
  }, [userCtx, location]);


  const handleDefaultPayment = async (id) => {
    const {
      stripe: { customerId },
    } = userCtx;
    await setDefaultPayment(customerId, id);
    const { data } = await getPaymentMethods(customerId);
    setPaymentCards(data);
    setchangeDefault(true);
    setShowErrorLegend(false);
    setShowChangeLegend(true);
    setNewPaymentMethodAddedLegend(false);
  };

  const handleRetryPay = async () => {
    setLoading(true);
    try {
      const token = await retryPaymenth(
        userCtx.stripe.subscriptionId,
        userCtx._id
      );
      console.log("new token", token);
      localStorage.setItem("token", token.data.token);
      setLoading(false);
      history.push("/succesfullPayment");
    } catch ({ message }) {
      console.log(message);
      setLoading(false);
      setShowChangeLegend(false);
      setShowErrorLegend(true);
    }
  };

  return (
    <>
      <Navbar />
      <PaymentFailureContainer>
        <div className="back" onClick={() => history.push("/")}>
          <ArrowIcon className="arrow" />
          <p>Regresar</p>
        </div>
        <div className="content">
          {showErrorLegend && <ErrorMessage />}
          {showChangeLegend && (
            <PaymentMethodChangedMessage paymenthCardChange={changeDefault} />
          )}
          {newPaymenMethodAddedLegend && <NewPaymentMethodAddedLegend />}
        </div>
        <div className="content">
          <p className="second-p">Estos son tus métodos de pago registrados:</p>
          <div className={paymentCards ? "methods" : "loading methods"}>
            {paymentCards &&
              paymentCards.map((card) => (
                <Method
                  key={card.id}
                  className="method"
                  onClick={async () => handleDefaultPayment(card.id)}
                >
                  {card.default ? <Checked /> : <Unchecked />}
                  <p className={card.default ? "blue" : "gray"}>
                    •••• •••• •••• {card.card.last4}
                  </p>
                  <p className="card__brand">
                    {card.brand === "visa" ? <VisaIcon /> : <MastercardIcon />}
                  </p>
                </Method>
              ))}
          </div>
          <button
            className="add__new-payment"
            onClick={() => history.push("/newPaymentMethod")}
          >
            <p>Añadir nuevo método de pago</p>
          </button>
        </div>
        <ButtonCont
          onClick={() => {
            handleRetryPay();
          }}
          disabled={loading}
        >
          {loading ? (
            <BeatLoader
              color={"#fff"}
              loading={true}
              css={override}
              size={15}
              margin={2}
            />
          ) : (
            <p className="buttonText">Continuar</p>
          )}
        </ButtonCont>
      </PaymentFailureContainer>
    </>
  );
};

const ErrorMessage = () => {
  return (
    <>
      <AlertIcon />
      <h1>No se ha podido validar tu cuenta</h1>
      <p>
        Lo sentimos, tu último pago no ha podido ser procesado, por favor
        verifica tu método de pago o agrega uno nuevo.
      </p>
    </>
  );
};

const PaymentMethodChangedMessage = () => {
  return (
    <>
      <SuccessIcon className="success__icon" />
      <h1>Metodo de pago cambiado</h1>
      <p>
        Por favor, selecciona la tarjeta con la que deseas reintentar tu pago
      </p>
    </>
  );
};

const NewPaymentMethodAddedLegend = () => {
  return (
    <>
      <SuccessIcon className="success__icon" />
      <h1>Tu método de pago se agregó correctamente</h1>
      <p>
        Por favor, selecciona la tarjeta con la que deseas reintentar tu pago
      </p>
    </>
  );
};

export default PaymentFailure;
