import { useHistory } from "react-router-dom";

// Import styles
import { NavbarContainer } from "../styles/components/NavbarContainer.styled";

// Import logos
// import { ReactComponent as MDMLogo } from "../assets/logos/MDM_Logo.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow_left_icon.svg";
import { ReactComponent as MDMIcon } from "../assets/icons/MDMIcon.svg";
import ProfileIcon from "./ProfileIcon";

const Navbar = ({ title, hideProfile, hideBack }) => {
  const history = useHistory();

  return (
    <NavbarContainer>
      <div className="navbar__container">
        {hideBack ?
          <div className='navbar__icon'>
            <MDMIcon />
          </div>
          :
          <div onClick={() => history.goBack()}  style={{ marginTop: '5px' }} className='navbar__icon'>
            <ArrowIcon className="header__arrow" />
          </div>
        }
        <h3 className="navbar__title">{title}</h3>
        {hideProfile ? (
          <div className='navbar__iconprof'>
            <ProfileIcon pushToProfile={() => history.push("/profile")} className="navbar__icon" />
          </div>
        ) : (
          <div className='navbar__icon' />
        )}
        {/* </div> */}
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
