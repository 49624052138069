import styled from "styled-components";
import { colors } from "../variables";

const InputAccountCancelContainer = styled.div`
  align-self: center;
  margin-top: 0;
  margin-bottom: 150px;
  width: 280px;

  input {
    border-radius: 6px;
    border: 0.5px solid ${colors.grayBorder};
    height: 46px;
    margin-top: 5px;
    padding: 15px;
    width: 100%;
  }

  .border__green {
    border: 0.5px solid #6bac6c;
  }

  .account-cancel__label {
    font-size: 13px;
    font-weight: 700;
    line-height: 19.07px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: 440px;

    input {
      align-self: center;
      width: 70%;
    }

    .account-cancel__label {
      margin-left: 15%;
      text-align: left;
    }
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 550px;

    input {
      align-self: center;
      width: 70%;
    }

    .account-cancel__label {
      font-size: 16px;
      margin-left: 15%;
      text-align: left;
    }
  }
`;

export default InputAccountCancelContainer;
