import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";

// Import components
import BoxAuthOptions from '../../components/auth/BoxAuthOptions'
import FormLogin from '../../components/auth/FormLogin'
import FormSignup from '../../components/auth/FormSignup'

// Import styles
import { PageAuth } from '../../styles/auth/PageAuth.styled'
import { Back } from '../../styles/components/Back.styled';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Import icons
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow_left_icon.svg';
import { ReactComponent as IconMDM } from "../../assets/logoM.svg";
import share from "../../assets/icons/ios_share.svg";
import add from "../../assets/icons/ios_add.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";

const BannerToInstallApp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  background-color: #ffe990;
  padding: 0 5%;
  align-items: center;
  justify-content: space-evenly;
  svg {
    width: 40px;
  }
  p {
    font-size: 12px;
    width: 200px;
  }
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .share {
    width: 15px;
    height: 15px;
  }
  .add {
    width: 15px;
    height: 15px;
  }
`

const Login = () => {
  const history = useHistory();
  const [options, setOptions] = useState(true);
  const [signup, setSignup] = useState(false);
  // eslint-disable-next-line
  const [loadin, setLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [beforeInstall, setBeforeInstall] = useState(null);
  const [showIosBanner, setshowIosBanner] = useState(false);

  const changeOptions = () => {
    setOptions(true)
    setSignup(false)
  }

  // const das = jwt_decode(data);
  // console.log(das);
   
  const data = localStorage.getItem("token");
  if (data != null) {
    console.log(data);
    history.push('/home')
  }
  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isInStandaloneMode()) {
      setshowIosBanner(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.W
      setBeforeInstall(e)
      // Update UI notify the user they can install the PWA
      setShowBanner(true)
    });
  }, [])

  const handleAppInstall = () => {
    if (beforeInstall !== null) {
      beforeInstall.prompt();
      beforeInstall.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setBeforeInstall(null)
      });
    }
  }
  return (
    <>

      {options &&
        <PageAuth height='100vh'>
          <BoxAuthOptions
            setOptions={setOptions}
            setSignup={setSignup}
            options={options}
            signup={signup}
          />
        </PageAuth>}

      {!options && !signup &&
        <>
          <Back onClick={changeOptions}>
            <ArrowIcon className="arrow" />
            <p>Regresar</p>
          </Back>
          <PageAuth>
            <FormLogin />
          </PageAuth>
        </>}

      {!options && signup && <>
        <Back onClick={changeOptions}>
          <ArrowIcon className="arrow" />
          <p>Regresar</p>
        </Back>
        <PageAuth>
          <FormSignup setLoading={setLoading} />
        </PageAuth>
      </>}


      {isMobile &&
        showBanner &&
        <BannerToInstallApp onClick={() => handleAppInstall()}>
          <IconMDM />
          <p>Instala la aplicación en tu celular haciendo click aquí.</p>
          <Close onClick={() => setShowBanner(false)} />
        </BannerToInstallApp>
      }
      {isMobile &&
        showIosBanner &&
        <BannerToInstallApp
          onClick={() => setshowIosBanner(false)}
        >
          <IconMDM />
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <p style={{
              textAlign: 'left',
              fontSize: '11px'
            }}>
              Instala esta app en tu iPhone: Presiona
            </p>
            <p style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '11px'
            }}>
              <img src={share} className='share' alt='share' />
              y añádela a tu pantalla de inicio
              <img src={add} className='add' alt='add' />.
            </p>
          </div>
          <div
            onClick={() => setshowIosBanner(false)}
            style={{
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Close
              style={{
                width: '10px',
                height: '10px'
              }}
            />

          </div>
        </BannerToInstallApp>
      }

    </>
  )
}

export default Login