import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";
import jwt_decode from "jwt-decode";

// Import services
import { cancelSubscription } from "../services/stripeServices";

import CancelAccountContainer from "../styles/ManageAccount/CancelAccountContainer.styled";
import Navbar from "../components/Navbar";

import AlertIcon from "../components/AlertIcon";
import { ReactComponent as SuccessIcon } from "../assets/icons/Correcto.svg";
import InputAccountCancel from "../components/InputAccountCancel";

const CancelAccount = () => {
  const [accountCanceled, setAccountCancel] = useState(false);
  const [cancelInputValue, setCancelInputValue] = useState("");
  const [cancelConfirmed, setCancelConfirmed] = useState(false);
  const data = localStorage.getItem("token");
  const { userForToken } = jwt_decode(data);

  const history = useHistory();

  const cancelPlan = async () => {
    await cancelSubscription(
      userForToken.stripe.subscriptionId,
      userForToken._id
    );
    setAccountCancel(true);
  };

  const pushToManageAccount = () => {
    history.push("/manageAccount");
  };

  const endOfSubscription = moment(
    userForToken?.stripe.nextCharge * 1000
  ).format("DD [/] MMMM [/] YYYY");

  return (
    <>
      <Navbar title="CANCELAR MI CUENTA" hideProfile={false} />
      <CancelAccountContainer>
        {!accountCanceled ? (
          <div className="cancel-info__container">
            <AlertIcon />
            <div className="cancel-title__container">
              <h2 className="cancel__title">
                Confirmación de la cancelación requerida
              </h2>
            </div>
            <InputAccountCancel
              cancelInputValue={cancelInputValue}
              setCancelInputValue={setCancelInputValue}
              cancelConfirmed={cancelConfirmed}
              setCancelConfirmed={setCancelConfirmed}
            />
          </div>
        ) : (
          <div className="cancel-success__container">
            <SuccessIcon />
            <h2 className="cancel-success__title">
              La cuenta se cancelará al término de tu suscripción el día{" "}
              {endOfSubscription}
            </h2>
          </div>
        )}
        {cancelConfirmed && (
          <div className="confirm__container">
            <button
              className="confirm__button"
              onClick={accountCanceled ? pushToManageAccount : cancelPlan}
            >
              ACEPTAR
            </button>
          </div>
        )}
      </CancelAccountContainer>
    </>
  );
};

export default CancelAccount;
