import styled from "styled-components";

export const BoxHome = styled.div`
  .home__date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 13px;
    padding-top: 73px;
    text-align: center;
  }

  .home__newsletters {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .newsletter__select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .pdf__active {
        height: 350px;
      }
      .pdf__active__morning {
        height: 330px;
      }
      .pdf__inactive {
        height: 0;
      }
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    .home__newsletters {
      padding: 0 50px;
      .newsletter__select {
        .pdf__active {
          height: 700px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .home__date {
      font-size: 24px;
      line-height: 33px;
    }

    .home__newsletters {
      align-items: center;
      .newsletter__select {
        width: 636px;
        .pdf__active {
          height: 621px;
          margin-bottom: 35px;
        }
        .pdf__active__morning {
          height: 330px;
          margin-bottom: 35px;
        }
        .pdf__inactive {
          height: 0;
        }
      }
    }

    .newsletter__select:last-child {
      .select__covid {
        margin-bottom: 100px;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1443px) {
    .home__date__l {
      padding-top: 85px;
      text-align: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }

    .home__newsletters__l {
      display: flex;
      flex-direction: row;
      margin-top: 26px;
      padding: 0 8vw;
      .home__newsletter__l {
        width: 450px;
      }
    }
  }

  @media only screen and (min-width: 1444px) {
    .home__date__l {
      padding-top: 85px;
      text-align: center;
      font-family: "Oswald";
      font-size: 24px;
      font-weight: 600;
    }

    .home__newsletters__l {
      display: flex;
      height: 87vh;
      flex-direction: row;
      margin-top: 26px;
      padding: 0 8vw;
      width: 100%;
      .home__newsletter__l {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
