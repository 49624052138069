import styled from "styled-components";
import { colors } from "../variables";

const FormEmailAuthContainer = styled.div `
  margin: 0 auto;
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .email-login {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      /* margin-bottom: 50px; */
    }

    &__form {
      .input__container {
        margin-bottom: 42px;

        .email-login__label {
          font-size: 14px;
          line-height: 19px;
          font-weight: bold;
        }

        .email-login__input {
          width: 100%;
          height: 46px;
          border: 0.5px solid ${colors.grayBorder};
          border-radius: 6px;
          padding: 15px;
          margin-top: 5px;
        }

        .password__container {
          display: flex;
          align-items: center;

          input {
            width: 100%;
            height: 46px;
            border: 0.5px solid ${colors.grayBorder};
            padding: 15px;
            margin-top: 5px;
            border-right: none;
            border-radius: 6px 0 0 6px;
          }

          .eye {
            border: 0.5px solid ${colors.grayBorder};
            border-left: none;
            border-radius: 0 6px 6px 0;
            height: 46px;
            padding-right: 5px;
            cursor: pointer;
            margin-top: 5px;
          }

          svg {
            path {
              fill: ${colors.lightGray};
            }
          }
        }
      }
    }
  }

  p {
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    color: ${colors.blueInteraction};
    margin-top: 36px;
  }

  .error {
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }

  .email-login__forgot-password {
    text-decoration: underline;
    margin-bottom: 110px;
  }

  .confirm__container {
    display: flex;
    justify-content: center;
    .confirm__button {
      background-color: ${colors.blueInteraction};
      border-radius: 50px;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-top: 50px;
      min-height: 40px;
      width: 200px;
    }

    .confirm__button:disabled {
      background-color: ${colors.blueInteractionInactive};
    }
  }

  @media only screen and (max-width: 767px) {
    width: 280px;
    padding: 0 0 63px 0;

    .email-login {
      &__title {
        margin-top: 99px;
        margin-bottom: 42px;
        font-size: 14px;
        line-height: 19.07px;
        /* margin-bottom: 42px; */
      }

      &__form {
        margin-top: 0;
        .input__container {
          margin-bottom: 30px;
        }
      }
    }

    .email-login__forgot-password {
      margin-top: 0;
      text-decoration: underline;
      margin-bottom: 0px;
    }

    .confirm__container {
      display: flex;
      justify-content: center;
      .confirm__button {
        width: 193px;
        border-radius: 50px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 0;

    .email-login {
      &__title {
        margin-top: 180px;
        margin-bottom: 100px;
      }

      &__form {
        margin-top: 0;
        .input__container {
          margin-bottom: 41px;
        }
      }
    }

    .email-login__forgot-password {
      margin-top: 0;
      text-decoration: underline;
    }

    .confirm__container {
      display: flex;
      margin-top: 60px;
      justify-content: center;
      .confirm__button {
        width: 200px;
        border-radius: 50px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    height: inherit;
    justify-content: start;
    margin-top: 104px;
    padding-top: 0;

    .email-login {
      &__title {
        /* margin-bottom: 79px; */
      }
    }
  }
`;

export default FormEmailAuthContainer;