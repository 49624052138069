import React from "react";

//Icons
import { ReactComponent as VisaIcon } from "../assets/icons/visa_icon.svg";
import { ReactComponent as MastercardIcon } from "../assets/icons/mastercard_icon.svg";
import { ReactComponent as Checked } from "../assets/icons/checked_icon.svg";
import { ReactComponent as Unchecked } from "../assets/icons/unchecked_box_icon.svg";

import CardDefaultPaymentContainer from "../styles/components/CardDefaultPayment.styled";

const CardDefaultPayment = ({ cardInfo, handleDefaultPayment, showexp=true }) => {
  const { card } = cardInfo;

  return (
    <CardDefaultPaymentContainer>
      <div
        className="payment-card__details"
        onClick={() => handleDefaultPayment(cardInfo.id)}
      >
        <p className="payment-card__box-check">
          {cardInfo.default ? <Checked /> : <Unchecked />}
        </p>
        <p className="payment-card__text">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span> </span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span> </span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span> </span>
          {card.last4}
        </p>
        <p className="payment-card__brand">
          {card.brand === "visa" ? <VisaIcon /> : <MastercardIcon />}
        </p>
      </div>
      {showexp &&      
        <p className="payment-card__expiration">
          Expira el {card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month}/
          {card.exp_year.toString()[2]}{card.exp_year.toString()[3]}
        </p>
      }
    </CardDefaultPaymentContainer>
  );
};

export default CardDefaultPayment;
