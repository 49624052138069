import styled from "styled-components";
import { colors } from "../variables";

const CardDefaultPaymentContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  // background-color: red;

  .payment-card__details {
    align-items: center;
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;
    margin-left: 20px;
    width: 260px;

    .payment-card {
      &__box-check {
        height: 26px;
        margin-right: 5px;
      }

      &__text {
        color: ${colors.blueInteraction};
        font-weight: 700;
        font-size: 14px;

        .dot {
          height: 4px;
          width: 4px;
          background-color: ${colors.blueInteraction};
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 2px;
        }
      }

      &__brand {
        align-self: center;
        padding-top: 7px;
        margin-left: 10px;
      }
    }
  }

  .payment-card__expiration {
    font-size: 12px;
    font-weight: 400;
    width: 60px;
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    align-self: left;
    justify-content: center;
    width: 80%;

    .payment-card__details {

      .payment-card__box-check {
        margin-right: 10px;
      }
    }

    .payment-card__expiration {
      width: 97px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    align-self: left;
    // justify-content: center;
    width: 80%;

    .payment-card__details {
      .payment-card__box-check {
        margin-right: 10px;
      }

      .payment-card {
        &__text {
          font-size: 14px;
          line-height: 19.07px;
        }
      }
    }

    .payment-card__expiration {
      font-size: 13px;
      line-height: 24.51px;
      width: 115px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    align-self: left;
    // justify-content: space-evenly;
    width: 85%;

    .payment-card__details {
      .payment-card {
        &__text {
          font-size: 14px;
          line-height: 19.07px;
        }
      }
    }

    .payment-card__expiration {
      font-size: 14px;
      line-height: 24.51px;
      width: 113px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1443px) {
    align-self: left;
    // justify-content: space-evenly;
    width: 90%;

    .payment-card__details {
      .payment-card {
        &__text {
          font-size: 14px;
          line-height: 19.07px;
        }
      }
    }

    .payment-card__expiration {
      font-size: 14px;
      line-height: 24.51px;
      // margin-left: 22px;
      width: 125px;
    }
  }

  @media only screen and (min-width: 1444px) {
    align-self: left;
    // justify-content: center;
    width: 90%;

    .payment-card__details {
      .payment-card {
        &__text {
          font-size: 14px;
          line-height: 19.07px;
        }
      }
    }

    .payment-card__expiration {
      font-size: 14px;
      line-height: 24.51px;
      // margin-left: 22px;
      width: 150px;
    }
  }
`;

export default CardDefaultPaymentContainer;