import { useState, useContext } from 'react'

// Import hooks
import useInput from '../../hooks/useInput';
import { Context } from '../../context';
import { useHistory } from 'react-router-dom';
// Import services
import { login, currentUser } from '../../services/auth'

// Import styles
import FormEmailAuthContainer from '../../styles/auth/FormEmailAuth.styled'

// Import icons
import { ReactComponent as EyeIcon } from '../../assets/icons/Eyeopen.svg';
import { ReactComponent as EyeIconClose } from "../../assets/icons/Eyeclose.svg";



const FormLogin = () => {
  const history = useHistory()
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const [typePassword, setTypePassword] = useState("password")

  const { setUserCtx, handlePaymentStatus } = useContext(Context)


  // Inputs value
  const emailInput = useInput('')
  const passwordInput = useInput('')

  const handleLogin = async e => {
    e.preventDefault()
    try {
      await login({
        email: emailInput.value,
        password: passwordInput.value
      })
      const user = await currentUser()
      setUserCtx(user.data.userForToken);
      const path = handlePaymentStatus(user.data.userForToken);
      history.push(`${path}`)
    } catch (error) {
      if (error.response.data.message === 'Missing credentials')
        setErrorPassword('Por favor, provee todos los datos para ingresar')
      if (error.response.data.message === 'No se puede iniciar sesión')
        setErrorPassword(error.response.data.message)
      if (error.response.data.message === 'Contraseña incorrecta')
        setErrorPassword(error.response.data.message)
      if (error.response.data.message === 'No existe alguna cuenta con este correo')
        setErrorEmail(error.response.data.message)
    }
  }

  const handleChange = () => {
    setErrorEmail(null)
    setErrorPassword(null)
  }

  const viewPassword = () => {
    if (typePassword === "password") {
      setTypePassword("text")
    } else {
      setTypePassword("password")
    }
  }

  return (
    <FormEmailAuthContainer>
      <h2 className="email-login__title">Ingresa</h2>
      <form className="email-login__form" onSubmit={handleLogin} onChange={handleChange}>
        <div className="input__container">
          <label className="email-login__label" htmlFor="email" >Correo</label>
          <input className="email-login__input" type="email" name="email" id="email" placeholder="Ingresa tu correo" {...emailInput} />
        </div>
        {errorEmail && <p className="error">{errorEmail}</p>}
        <div className="input__container">
          <label className="email-login__label" htmlFor="password" >Contraseña</label>
          <div className="password__container">
            <input type={typePassword} name="password" id="password" placeholder="Ingresa tu contraseña" {...passwordInput} className="password" />
            {typePassword === "password" ?
              <EyeIcon className="eye" onClick={viewPassword} />
              :
              <EyeIconClose className="eye" onClick={viewPassword} />
            }
          </div>
        </div>
        {errorPassword && <p className="error">{errorPassword}</p>}
        <p className="email-login__forgot-password" onClick={() => history.push('/recoverPassword')} >Olvidé mi contraseña</p>
        <div className="confirm__container">
          <button className="confirm__button" type="submit" disabled={!emailInput.value || !passwordInput.value}>Continuar</button>
        </div>
      </form>
    </FormEmailAuthContainer>
  )
}

export default FormLogin;