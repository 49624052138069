import styled from "styled-components";
import { colors } from "../variables";

const ChangePlanContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  padding-top: 100px;
  width: 100vw;

  .alert__icon {
    align-self: center;
    height: 38px;
    margin-bottom: 10px;
    width: 38px;
  }

  ${
    "" /* .change-plan__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: space-between;
    margin-bottom: 34px;
    padding: 0 10%;
    width: 100%;
    .change-plan__back {
      color: ${colors.blueInteraction};
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      .change-plan__arrow {
        align-self: center;
        fill: ${colors.blueInteraction};
        height: 20px;
        margin-right: 7px;
        width: 12px;
      }
    }
  } */
  }

  .change-plan__info-container {
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: flex-start;
    padding: 0 10%;
    width: 100%;
    .change-plan {
      &__title {
        color: ${colors.darkGrayMDM};
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 13px;
        text-align: center;
      }

      &__text {
        color: ${colors.darkGrayMDM};
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
        text-align: center;

        span {
          color: ${colors.blueInteraction};
          font-weight: bolder;
        }
      }

      &__cost {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 25px 0 7px 0;

        .change-plan__text {
          color: ${colors.blueInteraction};
          font-family: DM Serif Display;
          font-size: 24px;
          font-weight: normal;
          line-height: 33px;
          margin-bottom: 10px;
        }

        .change-plan__price {
          color: ${colors.darkGrayMDM};
          font-size: 24px;
          font-weight: 700;
          line-height: 33px;
          margin-bottom: 10px;
          text-align: center;
        }
      }

      &__payment-method {
        display: flex;
        flex-direction: column;

        .payment-method__text {
          color: ${colors.darkGrayMDM};
          font-size: 12px;
          font-weight: 400;
          line-height: 16.34px;
          margin-bottom: 11px;
          text-align: center;
        }

        .payment-method__add {
          align-self: center;
          color: ${colors.blueInteraction};
          font-size: 13px;
          font-weight: 600;
          line-height: 17.7px;
          margin-top: 32px;
        }

        .payment-method__add:visited {
          color: ${colors.blueInteraction};
        }
      }

      &__button {
        align-self: center;
        background-color: ${colors.blueInteraction};
        border-radius: 50px;
        border: none;
        color: white;
        font-weight: bold;
        font: 18px "Open Sans";
        height: 57px;
        margin: 50px 0;
        width: 193px;
      }
    }
  }

  .change-plan__success {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 0 10%;
    width: 100%;

    .success {
      &__icon {
        height: 38.23px;
        margin: 100px 0 10px 0;
        width: 38.23px;
      }

      &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 20px;
      }

      &__text {
        font-size: 12px;
        line-height: 16.34px;
        margin-bottom: 10px;
        text-align: center;
        width: 300px;
      }

      &__date {
        color: ${colors.blueInteraction};
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
      }

      &__button {
        background-color: ${colors.blueInteraction};
        border-radius: 50px;
        border: none;
        color: white;
        font-weight: bold;
        font: 18px "Open Sans";
        height: 46px;
        margin-top: 174px;
        width: 193px;
      }
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    .change-plan {
      &__info-container {
        width: 90%;
      }

      &__payment-method {
        align-self: center;
        width: 90%;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 90px;

    .change-plan__info-container {
      padding: 0 20%;
      width: 90%;

      .change-plan__payment-method {
        .payment-method__text {
          font-size: 14px;
        }

        .payment-method__add {
          font-size: 14px;
        }
      }

      .change-plan__button {
        width: 193px;
        align-self: center;
      }
    }

    .change-plan__success {
      padding: 0 20%;

      .success {
        &__title {
          font-size: 16px;
        }

        &__text {
          font-size: 14px;
          line-height: 20px;
          width: 450px;
        }

        &__date {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 90px;

    .change-plan__info-container {
      padding: 0 25%;
      width: 90%;

      .change-plan__payment-method {
        .payment-method__text {
          font-size: 14px;
        }

        .payment-method__add {
          font-size: 14px;
        }
      }

      .change-plan__button {
        align-self: center;
        width: 193px;
      }
    }

    .change-plan__success {
      padding: 0 20%;

      .success {
        &__title {
          font-size: 17px;
        }

        &__text {
          font-size: 15px;
          line-height: 20px;
          width: 450px;
        }

        &__date {
          font-size: 17px;
        }
      }
    }
  }
`;
export default ChangePlanContainer;
