import { useContext } from "react";
import { useHistory } from "react-router-dom";

// import Navbar from "../Navbar";
import Header from "../Header";
import ProfileConfiguration from "./ProfileConfiguration";
import PasswordConfiguration from "./PasswordConfiguration";

// Import hooks
import useInput from "../../hooks/useInput";
import { Context } from "../../context";

// Import services
import { updateUserInfo } from "../../services/profile";

const FormUserConfig = () => {
  const { userCtx, setUserCtx } = useContext(Context);
  const history = useHistory();
  const pathName = history.location.pathname;
  const nameInput = useInput(userCtx?.name);
  const emailInput = useInput(userCtx?.email);
  const birthdayInput = useInput(userCtx?.birthday);

  const handleProfileChangeSubmit = async (e) => {
    e.preventDefault();
    const userUpdate = await updateUserInfo(userCtx._id, {
      name: nameInput.value,
      email: emailInput.value,
      birthday: birthdayInput.value,
    });
    setUserCtx(userUpdate.data);
    history.push("/profile");
  };

  return (
    <>
      {/* <Navbar /> */}
      <Header history={history} />
      {pathName === "/profile/config" ?
        <ProfileConfiguration
          handleProfileChange={handleProfileChangeSubmit}
          nameInput={nameInput}
          emailInput={emailInput}
          birthdayInput={birthdayInput}
        />
        : pathName === "/profile/changePassword" ?
          <PasswordConfiguration />
          : history.push("/profile")}
    </>
  );
};

export default FormUserConfig;
