import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Import pages
// import Landing from './pages/Landing';
import Subscription from './pages/auth/Subscription';
import SuccessPayment from './pages/auth/SuccessPayment';
import Login from './pages/auth/Login';
import ProcessLogin from './pages/auth/ProcessLogin';
import Home from './pages/Home';
import Profile from './pages/Profile';
import ProfileConfig from './pages/ProfileConfig';
import ManageAccount from './pages/ManageAccount';
import PaymentMethod from './pages/AddNewPaymentMethod';
import ChangePlan from './pages/ChangePlan';
import PaymentFailure from './pages/PaymentFailure';
import SuccessfulPayment from './pages/SuccessfulPayment';
import CancelAccount from "./pages/CancelAccount";
import RecoverPassword from './pages/RecoverPassword';
import SetNewPassword from './pages/SetNewPassword';


const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Login} path='/' exact />
        <Route component={Subscription} path='/subscription' exact />
        <Route component={Login} path='/login' exact />
        <Route component={SuccessPayment} path='/success' exact />
        <Route component={ProcessLogin} path='/loading' exact />
        <Route component={Home} path='/home' exact />
        <Route component={Profile} path='/profile' exact />
        <Route component={ProfileConfig} path='/profile/config' exact />
        <Route component={ProfileConfig} path='/profile/changePassword' exact />
        <Route component={ManageAccount} path='/manageAccount' exact />
        <Route component={ChangePlan} path='/changeplan' exact />
        <Route component={PaymentMethod} path='/newPaymentMethod' exact />
        <Route component={CancelAccount} path='/cancelAccount' exact />
        <Route component={PaymentFailure} path='/paymentFailure' exact />
        <Route component={SuccessfulPayment} path='/succesfullPayment' exact />
        <Route component={RecoverPassword} path='/recoverPassword' exact />
        <Route component={SetNewPassword} path='/boletines/newPassword/:id/:token' exact />
      </Switch>
    </BrowserRouter>
  )
}

export default Router