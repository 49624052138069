import { createGlobalStyle } from 'styled-components'

import OpenSans from '../assets/fonts/opensans.ttf'
import CrimsonPro from '../assets/fonts/crimsonpro.ttf'
import Roboto from '../assets/fonts/roboto.ttf'
import Oswald from '../assets/fonts/oswald.ttf'

export default createGlobalStyle `

  @font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(${OpenSans}) format("truetype");
  }

  @font-face {
    font-family: "Crimson Pro";
    font-style: normal;
    font-weight: 400;
    src: local("Crimson Pro"), local("CrimsonPro"),
      url(${CrimsonPro}) format("truetype");
  }

  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto"),
      url(${Roboto}) format("truetype");
  }
  
  @font-face {
    font-family: "Oswald";
    font-style: normal;
    src: local("Oswald"), local("Oswald"),
      url(${Oswald}) format("truetype");
  }

  * {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Open Sans';
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    min-height: 100%;
    padding: 0;
    width: 100vw;
  }

  body #root {
    ${'' /* height: 100vh; */}
  }
`