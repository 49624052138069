//Import styles
import { SelectBoletinBtn } from '../../styles/home/SelectBoletinBtn.styled'

const BoletinSelect = ({ title, setSelectBoletin, selectBoletin, setLoadingMananera, setLoadingMatutino, setLoadingNocturno, setLoadingCovid }) => {

  const handleSetLoader = (title) => {
    switch (title) {
      case 'MAÑANERAS':
        setLoadingMatutino(true);
        setLoadingNocturno(true);
        setLoadingCovid(true);
        break;
      case 'MATUTINO':
        setLoadingMananera(true);
        setLoadingNocturno(true);
        setLoadingCovid(true);
        break;
      case 'NOCTURNO':
        setLoadingCovid(true);
        setLoadingMananera(true);
        setLoadingMatutino(true);
        break;
      case 'COVID-19':
        setLoadingMananera(true);
        setLoadingMatutino(true);
        setLoadingNocturno(true);
        break;
      default:
        break;
    }
  }

  return (
    <SelectBoletinBtn onClick={() => {
      setSelectBoletin(title)
      handleSetLoader(title)
    }}>
      <h2 className={`boletin__title ${selectBoletin === title && title === "MAÑANERAS" ? 'selected selected__mananera' : selectBoletin === title && "selected"}`} id={title}>{title}</h2>
    </SelectBoletinBtn>
  )
}

export default BoletinSelect