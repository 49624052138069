import styled from "styled-components";

const AlertIconContainer = styled.div`
  align-self: center;
  margin-bottom: 10px;

  svg {
    height: 38px;
    width: 38px;
  }

  @media only screen and (min-width: 1023px) {
    svg {
      height: 45px;
      width: 45px;
    }
  }
`;

export default AlertIconContainer;
