import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

//import styles
import ProfileConfigurationContainer from "../../styles/profilePage/ProfileConfiguration.styled";
import { ReactComponent as EyeIcon } from '../../assets/icons/eye_icon.svg'
//import services
import { changePassword } from '../../services/auth';

const FormWithToasts = () => {
  const history = useHistory()
  const { addToast } = useToasts();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setshowPassword] = useState(false);

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  }

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  }

  const handleShowPassword = (e) => {
    e.preventDefault();
    setshowPassword(!showPassword);
  }

  const saveNewPassword = async (e) => {
    e.preventDefault();
    const data = localStorage.getItem("token");
    const { userForToken } = jwt_decode(data);
    if (!/(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{6,}/g.test(newPassword)) {
      addToast('Mínimo seis carácteres, una mayúscula y un número.', { appearance: 'error', autoDismiss: true });
    } else {
      const body = {
        currentPassword,
        newPassword
      }
      const res = await changePassword(userForToken._id, body);
      if (res.data.ok === true) {
        addToast(res.data.msg, { appearance: 'success', autoDismiss: true });
        setTimeout(() => {
          history.push('/profile')
        }, 5000);
      } else {
        addToast(res.data.msg, { appearance: 'error', autoDismiss: true });
      }
    }
  }

  return (
    <ProfileConfigurationContainer>
      <form className="profile__config-form">
        <div>
          <label htmlFor="name">Contraseña actual</label>
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={currentPassword}
            onChange={handleCurrentPassword}
          />
        </div>
        <label htmlFor="email">Contraseña nueva</label>
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={newPassword}
          onChange={handleNewPassword}
        />
        <EyeIcon className="eye" onClick={handleShowPassword} />
        <button className="profile__config-button" onClick={saveNewPassword}>Guardar cambios</button>
      </form>
    </ProfileConfigurationContainer>
  )
};

const PasswordConfiguration = () => (
  <ToastProvider
    placement="bottom-center"
  >
    <FormWithToasts />
  </ToastProvider >
);

export default PasswordConfiguration;