import styled from "styled-components";
import { colors } from "../variables";

const SuccessfulPaymentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .success-message__container {
    height: inherit;
    text-align: center;
    padding: 220px 5%;
    width: inherit;

    .success-img {
      height: 38.23px;
      margin-bottom: 10px;
      width: 38.23px;
    }
    .success-title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .success-message {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 180px;
    }

    .success__continue {
      align-self: center;
      background-color: ${colors.blueInteraction};
      border-radius: 50px;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-top: 50px;
      min-height: 40px;
      width: 193px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .success-message__container {
      .success-img {
        width: 75px;
      }

      .success-title,
      .success-message {
        font-size: 16px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .success-message__container {
      .success-img {
        width: 100px;
      }

      .success-title,
      .success-message {
        font-size: 18px;
      }

      .success-title {
        margin-bottom: 13px;
      }
    }
  }
`;

export default SuccessfulPaymentContainer;
