export const colors = {
  
  blueFacebook: '#3664A2',
  darkGrayMDM: '#323232',
  blueInteraction: '#235789',
  blueInteractionInactive: 'rgba(35, 87, 137, 0.6);',
  grayBorder: '#E0E0E0',
  mediumGrayMDM: '#575757',
  lightGray: "#868585",

  // Colors boletines
  yellowMatutino: '#EACD58',
  redMananera: '#FA4443',
  greenCovid: '#006261',
  darkNocturno: '#1F363D'
}