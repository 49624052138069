// Import styles
import { BoxAuth } from "../../styles/auth/BoxAuth.styled";

// Import logos and icons
import { ReactComponent as MDMLogo } from "../../assets/logos/MDM_Logo.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/Facebook.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google_icon.svg";
import { ReactComponent as MDMIcon } from "../../assets/icons/MDMIcon.svg";


const BoxAuthOptions = ({ setOptions, setSignup, options, signup }) => {


  const changeSignup = () => {
    setOptions(false);
    setSignup(true);
  };

  const changeLogin = () => {
    setOptions(false);
    setSignup(false);
  };

  return (
    <BoxAuth>
      <div className="logo__container">
        <MDMLogo className="logo" />
      </div>
      <div className="register-options__container">
        <h2>Accede para ver el contenido</h2>
        <div className="google">
          <GoogleIcon className="icon" />
          <a
            className="social-login__access"
            href={`${process.env.REACT_APP_SERVER_ENDPOINT}/boletines/google`}
          >
            Acceder con Google
          </a>
        </div>
        <div className="facebook">
          <FacebookIcon className="icon" />
          <a
            href={`${process.env.REACT_APP_SERVER_ENDPOINT}/boletines/facebook`}
          >
            Acceder con Facebook
          </a>
        </div>
        <div className="correo" onClick={changeLogin}>
          <MDMIcon className="icon" />
          <p>Acceder con Mail</p>
        </div>
        <div className="register">
          <p className="register__question">¿Aún no eres usuario?</p>
          <p className="register__invite" onClick={changeSignup}>
            Regístrate aquí
          </p>
        </div>
      </div>
    </BoxAuth>
  );
};

export default BoxAuthOptions;
